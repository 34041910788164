import type { Feature, Geometry } from "geojson";

export interface TemporalFeatureProperties {
  time: string;
}

export type TemporalFeature = Feature<
  Geometry | null,
  TemporalFeatureProperties
>;

export const isTemporalFeature = (
  feature: Feature<Geometry | null>,
): feature is TemporalFeature => {
  return !!feature.properties?.time;
};

import useGeojsonFromFileUrl from "../../../hooks/useGeojsonFromFileUrl";
import useStableFileUrl from "../../../hooks/useStableFileUrl";
import { useFitToGeojson } from "../FitToGeojson/FitToGeojson";
import RedMapLayer, { type RedMapLayerVariant } from "./RedMapLayer";

interface RedMapByPredictionProps {
  id: string;
  opacity?: number;
  potentialSpreadFileUrl: string | undefined;
  potentialEmbersFileUrl: string | undefined;
  variant?: RedMapLayerVariant;
}

const RedMapByFileUrl = ({
  id,
  opacity,
  variant,
  ...props
}: RedMapByPredictionProps) => {
  const potentialEmbersFileUrl = useStableFileUrl(props.potentialEmbersFileUrl);

  const { data: potentialEmbersGeojson, isError: isPotentialEmbersError } =
    useGeojsonFromFileUrl({
      fileUrl: potentialEmbersFileUrl,
      layerName: "red map potential embers layer",
    });

  const potentialSpreadFileUrl = useStableFileUrl(props.potentialSpreadFileUrl);

  const { data: potentialSpreadGeojson, isError: isPotentialSpreadError } =
    useGeojsonFromFileUrl({
      fileUrl: potentialSpreadFileUrl,
      layerName: "red map potential spread layer",
    });

  useFitToGeojson({
    geojson: potentialEmbersGeojson,
    id: `${id}-potential-embers`,
    isError: isPotentialEmbersError,
  });

  useFitToGeojson({
    geojson: potentialSpreadGeojson,
    id: `${id}-potential-spread`,
    isError: isPotentialSpreadError,
  });

  if (!potentialEmbersGeojson || !potentialSpreadGeojson) {
    return null;
  }

  return (
    <RedMapLayer
      id={id}
      opacity={opacity}
      potentialEmbersGeojson={potentialEmbersGeojson}
      potentialSpreadGeojson={potentialSpreadGeojson}
      variant={variant}
    />
  );
};

export default RedMapByFileUrl;

import type { PotentialImpactsInteractionState } from "../../map/PotentialImpacts/interactions";
import MapPopupDialog from "../SpatialPopup/MapPopupDialog";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import PotentialImpactsPopupDetail from "./PotentialImpactsPopupDetail";

interface PotentialImpactsPopupProps {
  hours: number;
  onClose: () => void;
  showBurnProbability?: boolean;
  state: PotentialImpactsInteractionState;
}

const PotentialImpactsPopup = ({
  hours,
  onClose,
  showBurnProbability = true,
  state: { id, isActive, properties },
}: PotentialImpactsPopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        anchor: "bottom",
        id,
        offset: 80,
        size: "md",
        type: "click",
      }}
    >
      {properties && (
        <MapPopupDialog
          title={properties.name || <>Name: &mdash;</>}
          onClose={onClose}
        >
          <PotentialImpactsPopupDetail
            hours={hours}
            properties={properties}
            showBurnProbability={showBurnProbability}
          />
        </MapPopupDialog>
      )}
    </SpatialPopup>
  );
};

export default PotentialImpactsPopup;

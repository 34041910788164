import {
  Close,
  IconButton,
  media,
  mediumSurfaceOverrides,
} from "@app/design-system";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import styled, { css } from "styled-components";

interface StyledDrawerProps {
  fullHeight?: boolean;
}

const fullHeightStyles = css`
  height: 100%;
`;

const StyledDrawer = styled.div<StyledDrawerProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  box-shadow: ${(p) => p.theme.boxShadows.md};
  width: 100%;
  max-height: 100%;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
  padding-bottom: 0.25rem;
  ${(p) => p.fullHeight && fullHeightStyles}

  @media ${media.lg} {
    width: 20.5rem;
  }

  @media ${media.xxl} {
    width: 22rem;
  }

  @media ${media.lg} {
    border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  }
`;

const StyledHeader = styled.div`
  display: grid;
  align-items: center;
  gap: 0.375rem;
  grid-template-columns: minmax(0, 1fr) auto;
  padding: 0.375rem 0.375rem 0.375rem 1rem;
  ${(p) => p.theme.typography.variants.buttonLg}
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};

  @media ${media.lg} {
    border-top-left-radius: ${(p) => p.theme.borderRadiuses.lg}px;
    border-top-right-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  }
`;

const StyledActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  min-height: 0;
`;

const StyledViewport = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  @media ${media.lg} {
    padding: calc(0.25rem - 1px) calc(0.25rem - 1px) 0;
  }
`;

const StyledScrollbar = styled.div`
  display: flex;
  padding: 0.125rem;
  /* ensures no selection */
  user-select: none;
  /* disable browser handling of all panning and zooming gestures on touch devices */
  touch-action: none;
  background-color: rgba(255, 255, 255, 0.6);
  transition: background-color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  &[data-orientation="vertical"] {
    width: 0.875rem;
  }
`;

const StyledThumb = styled.div`
  position: relative;
  flex: 1;
  border-radius: 0.75rem;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundStrong};
  transition: background-color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  &:hover {
    background-color: ${(p) => p.theme.colors.neutrals.backgroundStrongHover};
  }

  &:active {
    background-color: ${(p) => p.theme.colors.neutrals.backgroundStrongActive};
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 0.875rem;
  }
`;

const StyledFooter = styled.div`
  padding: 0.25rem 0.5rem;
  margin-top: 0.25rem;
  ${mediumSurfaceOverrides}
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  border-radius: 0 0 ${(p) => p.theme.borderRadiuses.lg}px
    ${(p) => p.theme.borderRadiuses.lg}px;
`;

interface DrawerProps {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  fullHeight?: boolean;
  label: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  "data-testid"?: string;
}

const Drawer = ({
  actions,
  children,
  footer,
  fullHeight,
  label,
  onClose,
  "data-testid": dataTestId,
}: DrawerProps) => {
  const closeButton = (
    <IconButton
      icon={Close}
      label="Close"
      onClick={onClose}
      size="sm"
      variant="ghost"
    />
  );

  return (
    <StyledDrawer fullHeight={fullHeight} data-testid={dataTestId}>
      <StyledHeader>
        {label}
        <StyledActions>
          {actions}
          {closeButton}
        </StyledActions>
      </StyledHeader>
      <ScrollArea.Root asChild>
        <StyledContent>
          <ScrollArea.Viewport>
            <StyledViewport>{children}</StyledViewport>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar asChild orientation="vertical">
            <StyledScrollbar>
              <ScrollArea.Thumb asChild>
                <StyledThumb />
              </ScrollArea.Thumb>
            </StyledScrollbar>
          </ScrollArea.Scrollbar>
        </StyledContent>
      </ScrollArea.Root>
      {footer && <StyledFooter>{footer}</StyledFooter>}
    </StyledDrawer>
  );
};

export default Drawer;

import last from "lodash/last";

interface GetImpactCountParams {
  hourlyCount: number[] | undefined;
  hoursFrom: number;
  hoursTo: number;
}

const getImpactCount = ({
  hourlyCount,
  hoursFrom,
  hoursTo,
}: GetImpactCountParams): number | undefined => {
  if (!hourlyCount) return;

  const counts = hourlyCount.filter(
    (count, index) => index >= hoursFrom && index <= hoursTo,
  );

  if (!counts.length) {
    return 0;
  }

  const minCount = counts[0];
  const maxCount = counts[counts.length - 1];

  // This calculation is brittle due to impact counts being cumulative
  // rather than individual. Attempts to locate the closest previous smaller
  // number than our minimum to determine its "base" value.
  // If there is none available, use either the first number or zero.
  const startCount =
    last(
      hourlyCount.filter(
        (count, index) => count < minCount && index < hoursFrom,
      ),
    ) ?? (hourlyCount[0] === minCount && hoursFrom !== 0 ? hourlyCount[0] : 0);

  return maxCount - startCount;
};

export default getImpactCount;

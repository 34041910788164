import type { FeatureCollection } from "geojson";
import type { SymbolLayout } from "mapbox-gl";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import useMapContext from "../Map/useMapContext";
import { isGeoJsonSource } from "../types";
import { loadIcon, loadImage } from "../utils/loadImage";
import { formatTimeProperties } from "./utils";

interface ManualPredictionGeometryProps {
  geojson: FeatureCollection;
  id: string;
}

const LABEL_LAYOUT_CONFIGURATION = {
  "text-field": ["get", "CAPTUREDDA"],
  "text-offset": [0, 1.5],
  "text-size": 12,
  "text-anchor": "top",
  "text-variable-anchor": ["bottom", "top", "left", "right"],
  "text-radial-offset": 1.3,
  "text-justify": "auto",
  "text-allow-overlap": false,
  "text-optional": true,
} satisfies SymbolLayout;

const LABEL_APPEARANCE_MIN_ZOOM = 12;

const ManualPredictionGeometryLayer = ({
  geojson,
  id,
}: ManualPredictionGeometryProps) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(id, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
    });

    loadIcon({
      imageId: "asterisk",
      map,
    }).catch(catchAbortError);

    map.addLayer(
      {
        id: `${id}-line`,
        type: "line",
        source: id,
        paint: {
          "line-color": "#D9261C",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      },
      MapLevel.FS_BACKGROUND,
    );

    loadImage({
      imageId: `${id}-point`,
      map,
      src: "/icons/fire-hot-spot.png",
    }).catch(catchAbortError);

    map.addLayer(
      {
        id: `${id}-point`,
        type: "symbol",
        source: id,
        layout: {
          "icon-image": `${id}-point`,
          "icon-allow-overlap": true,
          "icon-optional": false,
        },
        filter: ["==", "$type", "Point"],
      },
      MapLevel.FS_BACKGROUND,
    );

    map.addLayer(
      {
        id: `${id}-symbol-line`,
        type: "symbol",
        source: id,
        minzoom: LABEL_APPEARANCE_MIN_ZOOM,
        layout: {
          ...LABEL_LAYOUT_CONFIGURATION,
          "symbol-placement": "line",
        },
      },
      MapLevel.FS_BACKGROUND,
    );

    map.addLayer(
      {
        id: `${id}-symbol-point`,
        type: "symbol",
        source: id,
        minzoom: LABEL_APPEARANCE_MIN_ZOOM,
        layout: {
          ...LABEL_LAYOUT_CONFIGURATION,
          "symbol-placement": "point",
        },
        filter: ["==", "$type", "Point"],
      },
      MapLevel.FS_BACKGROUND,
    );

    return () => {
      if (map.getLayer(`${id}-line`)) {
        map.removeLayer(`${id}-line`);
      }
      if (map.getLayer(`${id}-point`)) {
        map.removeLayer(`${id}-point`);
      }
      if (map.getLayer(`${id}-symbol-line`)) {
        map.removeLayer(`${id}-symbol-line`);
      }
      if (map.getLayer(`${id}-symbol-point`)) {
        map.removeLayer(`${id}-symbol-point`);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [id, map]);

  useEffect(() => {
    const source = map?.getSource(id);
    if (isGeoJsonSource(source)) {
      const formattedGeojson = formatTimeProperties(geojson);
      source.setData(formattedGeojson);
    }
  }, [map, geojson, id]);

  return null;
};

export default ManualPredictionGeometryLayer;

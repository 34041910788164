import { Legend } from "@app/design-system";
import compact from "lodash/compact";
import sumBy from "lodash/sumBy";
import {
  fireFeatures,
  type LayerSet,
  type LayerWithLegend,
} from "../../../config/layers/layers";
import type { PredictionVisualisationType } from "../../../config/predictionProduct";
import LabelWithCount from "../../ui/LabelWithCount/LabelWithCount";
import { usePredictions } from "../../ui/PredictionsProvider/PredictionsProvider";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import type { UseActiveLayersReturn } from "../../util/ActiveLayersProvider/useActiveLayers";
import DrawerCell from "../DrawerOverlay/DrawerCell";
import type { DrawerHandleLayout } from "../DrawerOverlay/DrawerHandle";
import { DrawerOverlayGridArea } from "../DrawerOverlay/DrawerOverlayGrid";
import LegendDrawer from "./LegendDrawer";

interface GetLegendLayerSetsParams {
  isLayerActive: UseActiveLayersReturn["isLayerActive"];
  layerSets: LayerSet[];
}

export type LegendLayerSet = [LayerSet, LayerWithLegend[]];

/**
 * Create a map between all layer sets which contain layers that both:
 * - Have a legend configured
 * - Are active
 *
 * Then use this in the drawer count and pass it to the list for rendering.
 */
export const getLegendLayerSets = ({
  isLayerActive,
  layerSets,
}: GetLegendLayerSetsParams): LegendLayerSet[] => {
  return compact(
    layerSets.map((layerSet) => {
      const layersWithLegend = layerSet.layers.filter(
        (layer): layer is LayerWithLegend =>
          !!layer.legend && isLayerActive(layer.id),
      );

      if (!layersWithLegend.length) {
        return;
      }

      return [layerSet, layersWithLegend];
    }),
  );
};

interface GetLegendCountParams {
  activeVisualisationTypes: PredictionVisualisationType[];
  isLayerActive: UseActiveLayersReturn["isLayerActive"];
  legendLayerSets: LegendLayerSet[];
}

export const getLegendCount = ({
  activeVisualisationTypes,
  isLayerActive,
  legendLayerSets,
}: GetLegendCountParams) => {
  const legendLayerSetsLayerCount = sumBy(
    legendLayerSets,
    ([, layers]) => layers.length,
  );

  // The fire features legend is hardcoded into the legend list and just depends
  // on whether the layer is active or not.
  const fireFeaturesLegendCount = isLayerActive(fireFeatures.id) ? 1 : 0;

  const legendCount =
    legendLayerSetsLayerCount +
    activeVisualisationTypes.length +
    fireFeaturesLegendCount;

  return legendCount;
};

interface LegendDrawerCellProps {
  handleLayout?: DrawerHandleLayout;
}

const LegendDrawerCell = ({ handleLayout }: LegendDrawerCellProps) => {
  const {
    visualiserState: { layerSets },
  } = useVisualiser();
  const { isLayerActive } = useActiveLayersContext();
  const { getActiveVisualisationTypes } = usePredictions();

  const activeVisualisationTypes = getActiveVisualisationTypes();

  const legendLayerSets = getLegendLayerSets({ isLayerActive, layerSets });

  const legendCount = getLegendCount({
    activeVisualisationTypes,
    isLayerActive,
    legendLayerSets,
  });

  return (
    <DrawerCell
      alignment="end"
      gridArea={DrawerOverlayGridArea.LEGEND}
      handleLayout={handleLayout}
      icon={Legend}
      isHidden={!legendCount}
      label={<LabelWithCount count={legendCount}>Legends</LabelWithCount>}
      data-testid="legend-drawer"
    >
      <LegendDrawer legendLayerSets={legendLayerSets} />
    </DrawerCell>
  );
};

export default LegendDrawerCell;

import { Close, IconButton, Skeleton } from "@app/design-system";
import styled from "styled-components";

const StyledMapPopupDialog = styled.div`
  display: grid;
  gap: 1rem;
`;

const StyledContent = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: start;
  gap: 0.5rem;
  ${(p) => p.theme.typography.variants.subtitleMd}
`;

const StyledTitle = styled.div`
  flex: 1;
`;

const StyledClose = styled.div`
  margin-left: auto;
`;

const StyledBody = styled.div``;

const StyledFooter = styled.div`
  display: flex;
  justify-content: end;
  gap: 0.5rem;
`;

export const StyledSkeletonMapPopupDialogButton = styled(Skeleton)`
  font-size: 2.5rem;
`;

interface MapPopupDialogProps {
  children?: React.ReactNode;
  footer?: React.ReactNode;
  onClose: () => void;
  title?: React.ReactNode;
}

const MapPopupDialog = ({
  children,
  footer,
  onClose,
  title,
}: MapPopupDialogProps) => {
  return (
    <StyledMapPopupDialog>
      <StyledContent>
        {title && (
          <StyledHeader>
            <StyledTitle>{title}</StyledTitle>
            <StyledClose>
              <IconButton
                icon={Close}
                label="Close"
                onClick={onClose}
                size="sm"
                variant="ghost"
              />
            </StyledClose>
          </StyledHeader>
        )}
        <StyledBody>{children}</StyledBody>
      </StyledContent>
      {footer && <StyledFooter>{footer}</StyledFooter>}
    </StyledMapPopupDialog>
  );
};

export default MapPopupDialog;

import dissolve from "@turf/dissolve";
import { featureCollection, type GeometryTypes } from "@turf/helpers";
import type * as ArcGIS from "arcgis-rest-api";
import type { Feature, FeatureCollection, Point, Polygon } from "geojson";
import type mapboxgl from "mapbox-gl";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import { defaultParams } from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";

export const getZoomToClusterCallback =
  (
    map: mapboxgl.Map | maplibregl.Map,
    sourceId: string,
    clusterLayerId: string,
  ) =>
  (
    e:
      | (mapboxgl.MapMouseEvent & {
          features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData)
      | maplibregl.MapLayerMouseEvent,
  ) => {
    const [feature] = map.queryRenderedFeatures(
      // @ts-expect-error TS is getting confused when merging Mapbox and MapLibre overloads
      e.point,
      {
        layers: [clusterLayerId],
      },
    );
    const clusterId = feature?.properties?.cluster_id;

    const source = map.getSource(sourceId);
    if (!isGeoJsonSource(source)) return;
    source.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (feature.geometry.type === "Point") {
        map.easeTo({
          center: feature.geometry.coordinates as mapboxgl.LngLatLike,
          zoom,
        });
      }
    });
  };

type ESRIPolygon = ArcGIS.Polygon;

const HABITABLE_PROPERTIES_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/Athena/MapServer/0/query`;

export function extractFeaturesFromFeatureCollections(
  featureCollections: FeatureCollection[],
  geometryType: GeometryTypes = "Point",
) {
  return featureCollections
    .flatMap(({ features }) => features)
    .filter(
      (feature): feature is Feature<Point> =>
        feature.geometry.type === geometryType,
    );
}

const PAGE_RESULT_OFFSET = 1000;
const EMPTY_FEATURE_COLLECTION: FeatureCollection<Point> = {
  type: "FeatureCollection",
  features: [],
};

export function fetchBuildingsInPolygons(
  abortController: AbortController,
  accessToken: string,
) {
  return (polygon: { geometry: ESRIPolygon }) => {
    const queryOptions = new URLSearchParams({
      ...defaultParams,
      where: "is_residential='Yes'",
      geometryType: "esriGeometryPolygon",
      geometry: JSON.stringify(polygon.geometry),
    });

    const fetchBuildingCentroid = (
      resultOffset?: number,
      accumulatedData = EMPTY_FEATURE_COLLECTION,
    ): Promise<FeatureCollection<Point>> => {
      if (resultOffset) {
        queryOptions.set("resultOffset", resultOffset.toString());
      }
      return fetch(HABITABLE_PROPERTIES_URL, {
        method: "POST",
        signal: abortController.signal,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: queryOptions,
      })
        .then((res) => res.json())
        .then((data) => {
          const combinedData = {
            ...accumulatedData,
            features: [...accumulatedData.features, ...data.features],
          };
          if (data.exceededTransferLimit) {
            return fetchBuildingCentroid(
              (resultOffset ?? 0) + PAGE_RESULT_OFFSET,
              combinedData,
            );
          }
          return combinedData;
        });
    };
    return fetchBuildingCentroid();
  };
}

export function getOutlinePolygon(
  isManualPrediction: boolean,
  predictionFeatureCollection: FeatureCollection<Polygon>,
  clampedHours: number,
) {
  let polygonFeatures = [];
  if (isManualPrediction) {
    polygonFeatures = predictionFeatureCollection.features.filter(
      ({ geometry }) => geometry.type === "Polygon",
    );
  } else {
    polygonFeatures = predictionFeatureCollection.features.filter(
      (feature) => feature.properties?.burnProbabilities[clampedHours] > 0,
    );
  }

  if (polygonFeatures.length === 0) {
    return EMPTY_FEATURE_COLLECTION;
  }
  return dissolve(featureCollection(polygonFeatures));
}

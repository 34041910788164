export interface BaseLayer {
  id: string;
  imageSrc: string;
  label: string;
}

export const hereMapsLayer: BaseLayer = {
  label: "HERE Maps",
  id: "baseMap",
  imageSrc: "/base-map-images/base.png",
};

export const topographicLayer: BaseLayer = {
  label: "Topographic",
  id: "topographic",
  imageSrc: "/base-map-images/topo.png",
};

export const iconNswLayer: BaseLayer = {
  label: "ICON",
  id: "iconNsw",
  imageSrc: "/base-map-images/rfs-vector.png",
};

export const satelliteLayer: BaseLayer = {
  label: "Satellite",
  id: "satellite",
  imageSrc: "/base-map-images/satellite.png",
};

export const baseMapLayers: readonly BaseLayer[] = [
  hereMapsLayer,
  satelliteLayer,
  topographicLayer,
  iconNswLayer,
];

import styled from "styled-components";
import makeTestId from "../../../utils/makeTestId";
import LayersDrawerHeader from "../../ui/MapRail/LayersDrawer/LayersDrawerHeader";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import LayerListAccordion from "./LayerListAccordion";
import OptionLayersController from "./OptionLayersController";

const StyledHeader = styled.div`
  display: grid;
  gap: 0.75rem;
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.surfaceDivider};
  padding: 0.75rem 0.75rem 0.75rem;
`;

const StyledLayersList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  overflow: hidden;
`;

interface LayersListProps {
  "data-testid"?: string;
  isMapRailEnabled?: boolean;
}

const LayersList = ({
  "data-testid": dataTestId,
  isMapRailEnabled,
}: LayersListProps) => {
  const {
    visualiserState: { layerSets },
  } = useVisualiser();

  return (
    <StyledLayersList>
      <StyledHeader>
        {isMapRailEnabled ? <LayersDrawerHeader /> : <OptionLayersController />}
      </StyledHeader>
      {layerSets.map((layerSet) => {
        return (
          <LayerListAccordion
            key={layerSet.id}
            layers={layerSet.layers}
            title={layerSet.label}
            data-testid={makeTestId(dataTestId, layerSet.id)}
          />
        );
      })}
    </StyledLayersList>
  );
};

export default LayersList;

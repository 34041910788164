import type { PotentialImpactsInteractionState } from "../../map/PotentialImpacts/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import PotentialImpactsHoverPopupDetail from "./PotentialImpactsHoverPopupDetail";

interface PotentialImpactsHoverPopupProps {
  hours: number;
  onClose: () => void;
  showBurnProbability?: boolean;
  state: PotentialImpactsInteractionState;
}

const PotentialImpactsHoverPopup = ({
  hours,
  onClose,
  showBurnProbability = true,
  state: { id, isActive, properties },
}: PotentialImpactsHoverPopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        anchor: "bottom",
        id,
        offset: 80,
        size: "lg",
        type: "hover",
      }}
    >
      {properties && (
        <MapPopupView hasClose={false} onClose={onClose}>
          <PotentialImpactsHoverPopupDetail
            hours={hours}
            properties={properties}
            showBurnProbability={showBurnProbability}
          />
        </MapPopupView>
      )}
    </SpatialPopup>
  );
};

export default PotentialImpactsHoverPopup;

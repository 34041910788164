import { useEffect } from "react";
import type { PredictionAttributes } from "../../../../.rest-hooks/types/predictions.yml";
import { habitablePropertiesLayer } from "../../../config/layers/layers";
import {
  isManualUploadPredictionAttributes,
  isRapidAppreciationPredictionAttributes,
} from "../../../config/predictionProduct";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import type { LayerConfigState } from "../../util/ActiveLayersProvider/useActiveLayers";
import { useRelativeHours } from "../../util/TimelineProvider/TimelineProvider";
import { PotentialImpactsHabitableProperties } from "../PotentialImpactsHabitableProperties/PotentialImpactsHabitableProperties";
import PotentialImpactsLayer from "./PotentialImpactsLayer";

const getPredictionGeojsonUrl = (
  predictionAttributes: PredictionAttributes,
) => {
  if (
    isManualUploadPredictionAttributes(predictionAttributes) &&
    predictionAttributes.result
  ) {
    return predictionAttributes.result?.outputFileUrl;
  }

  if (
    isRapidAppreciationPredictionAttributes(predictionAttributes) &&
    predictionAttributes.result
  ) {
    return predictionAttributes.result.burnProbabilityFileUrl;
  }
};

const useShowHabitableProperties = (
  predictionAttributes: PredictionAttributes,
) => {
  const { activateLayer } = useActiveLayersContext();
  useEffect(() => {
    if (predictionAttributes.impactCountHabitable) {
      activateLayer<LayerConfigState<typeof habitablePropertiesLayer>>({
        id: habitablePropertiesLayer.id,
        source: "layers-drawer",
        state: {
          habitablePropertiesOption: { isActive: true, opacity: 1 },
          habitablePropertiesSwimmingPools: { isActive: true, opacity: 1 },
        },
      });
    }
  }, [activateLayer, predictionAttributes]);
};

interface PotentialImpactsByPredictionProps {
  opacity: number;
  predictionAttributes: PredictionAttributes;
  predictionId: string;
  showBurnProbability?: boolean;
}

const PotentialImpactsByPrediction = ({
  opacity,
  predictionAttributes,
  predictionId,
  showBurnProbability,
}: PotentialImpactsByPredictionProps) => {
  const { impactResultUri, validFrom } = predictionAttributes;

  const validFromMilliseconds = validFrom ? validFrom * 1000 : undefined;
  const hours = useRelativeHours(validFromMilliseconds);
  const impactsUrl = impactResultUri?.url;

  useShowHabitableProperties(predictionAttributes);

  if (!impactsUrl) {
    return null;
  }

  const geojsonUrl = getPredictionGeojsonUrl(predictionAttributes);
  const isManualPrediction =
    isManualUploadPredictionAttributes(predictionAttributes);

  return (
    <>
      <PotentialImpactsLayer
        geojsonUrl={impactsUrl}
        hours={hours}
        id={`${predictionId}-impacts`}
        opacity={opacity}
        showBurnProbability={showBurnProbability}
      />
      <PotentialImpactsHabitableProperties
        id={predictionId}
        geojsonUrl={geojsonUrl}
        hours={hours}
        opacity={opacity}
        isManualPrediction={isManualPrediction}
      />
    </>
  );
};

export default PotentialImpactsByPrediction;

import { useUpdatingRef } from "@kablamo/kerosene-ui";
import { useEffect } from "react";
import {
  useMapInteractionsContext,
  type GetPropertiesFromFeatureFn,
} from "./MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  InteractiveMapElement,
} from "./types";
import useLayerInteractionState from "./useLayerInteractionState";

interface UseLayerInteractionsParams<P extends FeatureInteractionProperties> {
  element?: InteractiveMapElement;
  getPropertiesFromFeature: GetPropertiesFromFeatureFn<P>;
  layerId: string;
  onClick?: (properties: P) => void;
}

const useLayerInteractions = <P extends FeatureInteractionProperties>({
  element = "feature",
  getPropertiesFromFeature,
  layerId,
  onClick,
}: UseLayerInteractionsParams<P>) => {
  const context = useMapInteractionsContext<P>();

  if (context === undefined) {
    throw new Error(
      "useLayerInteractions must be used within a MapInteractionsProvider",
    );
  }

  const callbackRef = useUpdatingRef({ getPropertiesFromFeature, onClick });

  const { register, unregister } = context;

  useEffect(() => {
    if (!callbackRef.current) return;

    register({
      layer: {
        ...callbackRef.current,
        element,
        layerId,
      },
    });

    return () => {
      unregister({ layerId });
    };
  }, [callbackRef, element, layerId, register, unregister]);

  const interactionState = useLayerInteractionState<P>({ layerId });

  return interactionState;
};

export default useLayerInteractions;

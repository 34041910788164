import { clamp } from "@kablamo/kerosene";
// NOTE: @sentry/tracing MUST be imported when using Sentry.startTransaction or else the transaction is not returned
// @see https://github.com/getsentry/sentry-javascript/issues/4731
import "@sentry/tracing";
import * as Sentry from "@sentry/nextjs";
import type { ErrorEvent, EventData, MapSourceDataEvent } from "mapbox-gl";
import { useEffect, useState } from "react";
import PotentialImpactsHoverPopup from "../../popup/PotentialImpactsPopup/PotentialImpactsHoverPopup";
import PotentialImpactsPopup from "../../popup/PotentialImpactsPopup/PotentialImpactsPopup";
import useMapContext from "../Map/useMapContext";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import {
  POTENTIAL_IMPACTS_MARKER_LAYER_ID,
  POTENTIAL_IMPACTS_SOURCE_ID,
} from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import usePotentialImpactsMapData from "./usePotentialImpactsMapData";
import usePotentialImpactsMapLayers from "./usePotentialImpactsMapLayers";

interface SourceError {
  message: string;
  status: number;
  url: string;
}

const isSourceError = (error: unknown): error is SourceError => {
  if (!error) return false;
  const sourceError = error as Partial<SourceError>;
  return (
    typeof sourceError.url === "string" &&
    typeof sourceError.status === "number"
  );
};

interface PotentialImpactsLayerProps {
  geojsonUrl: string;
  hours: number;
  id: string;
  opacity: number;
  showBurnProbability?: boolean;
}

const PotentialImpactsLayer = ({
  geojsonUrl,
  hours,
  id,
  opacity,
  showBurnProbability,
}: PotentialImpactsLayerProps) => {
  const map = useMapContext();

  const [, setIsLoaded] = useState<boolean>(false);
  const [, setIsError] = useState<boolean>(false);

  const clampedHours = clamp(0, hours, 11);

  useEffect(() => {
    const onError = (event: ErrorEvent & EventData) => {
      if (isSourceError(event.error) && event.error.url === geojsonUrl) {
        setIsError(true);
      }
    };

    map.on("error", onError);

    return () => {
      map.off("error", onError);
    };
  }, [geojsonUrl, id, map]);

  useEffect(() => {
    const transaction = Sentry.startTransaction({
      name: "Potential Impacts Layer",
    });

    const span = transaction.startChild({
      op: "layer-performance",
      description: "Potential Impacts Layer load time",
      data: {},
    });
    const onSourceData = (event: MapSourceDataEvent & EventData) => {
      if (
        event.sourceId === `${POTENTIAL_IMPACTS_SOURCE_ID}-${id}` &&
        event.isSourceLoaded
      ) {
        setIsLoaded(true);
        setIsError(false);
        span.finish();
        transaction.finish();
      }
    };

    map.on("sourcedata", onSourceData);

    return () => {
      map.off("sourcedata", onSourceData);
    };
  }, [geojsonUrl, id, map]);

  usePotentialImpactsMapLayers({
    hours: clampedHours,
    id,
    opacity,
    showBurnProbability,
  });

  usePotentialImpactsMapData({
    geojsonUrl,
    id,
  });

  const {
    deactivateClickState,
    deactivateHoverState,
    clickedState,
    hoveredState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: `${POTENTIAL_IMPACTS_MARKER_LAYER_ID}-${id}`,
  });

  return (
    <>
      <PotentialImpactsHoverPopup
        hours={clampedHours}
        onClose={deactivateHoverState}
        showBurnProbability={showBurnProbability}
        state={hoveredState}
      />
      <PotentialImpactsPopup
        hours={clampedHours}
        onClose={deactivateClickState}
        showBurnProbability={showBurnProbability}
        state={clickedState}
      />
    </>
  );
};

export default PotentialImpactsLayer;

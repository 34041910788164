import { media } from "@app/design-system";
import styled from "styled-components";

export enum DrawerOverlayGridArea {
  MAP = "map",
  LEGEND = "legend",
  LAYERS = "layers",
  CONTENT = "content",
  CONTROLS = "controls",
}

const StyledDrawerOverlayGrid = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-areas: "${DrawerOverlayGridArea.CONTENT}";
  gap: 1rem;
  flex: 1;
  overflow: clip;
  min-height: 0;
  pointer-events: none;

  @media ${media.lg} {
    gap: 0.5rem;
    padding: 0.5rem;
    grid-template-columns: auto minmax(0, 1fr) auto auto;
    grid-template-rows: auto minmax(0, 1fr) minmax(3rem, auto);
    grid-template-areas:
      "${DrawerOverlayGridArea.MAP} ${DrawerOverlayGridArea.MAP} ${DrawerOverlayGridArea.LEGEND} ${DrawerOverlayGridArea.LAYERS}"
      "${DrawerOverlayGridArea.CONTENT} . ${DrawerOverlayGridArea.LEGEND} ${DrawerOverlayGridArea.LAYERS}"
      "${DrawerOverlayGridArea.CONTROLS} ${DrawerOverlayGridArea.CONTROLS} ${DrawerOverlayGridArea.CONTROLS} ${DrawerOverlayGridArea.CONTROLS}";
  }
`;

interface DrawerOverlayGridProps {
  children: React.ReactNode;
}

const DrawerOverlayGrid = ({ children }: DrawerOverlayGridProps) => {
  return <StyledDrawerOverlayGrid>{children}</StyledDrawerOverlayGrid>;
};

export default DrawerOverlayGrid;

import { Text } from "@app/design-system";
import styled from "styled-components";
import type { PotentialImpactsInteractionProperties } from "../../map/PotentialImpacts/interactions";

const StyledPotentialImpactsPopup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export interface PotentialImpactsPopupDetailProps {
  hours: number;
  properties: PotentialImpactsInteractionProperties;
  showBurnProbability: boolean;
}

const PotentialImpactsPopupDetail = ({
  hours,
  properties,
  showBurnProbability,
}: PotentialImpactsPopupDetailProps) => {
  const burnProbability = `Impact probability: ${Math.round(
    properties.burnProbabilities[hours] * 100,
  )}%`;

  return (
    <StyledPotentialImpactsPopup>
      <Text size="subtitleMd">{properties.type || <>Type: &mdash;</>}</Text>
      {showBurnProbability && <Text size="bodyDefault">{burnProbability}</Text>}
    </StyledPotentialImpactsPopup>
  );
};

export default PotentialImpactsPopupDetail;

import type { FeatureCollection } from "geojson";
import formatDate from "../../../utils/formatDate/formatDate";
import { isTemporalFeature } from "./types";

export const formatTimeProperties = (
  geojson: FeatureCollection,
): FeatureCollection => {
  return {
    ...geojson,
    features: geojson.features.map((feature) => {
      if (isTemporalFeature(feature)) {
        return {
          ...feature,
          properties: {
            ...feature.properties,
            time: formatDate(feature.properties.time, "timeAndDate"),
          },
        };
      }

      return feature;
    }),
  };
};

import type { ManualIncidentPredictionAttributes } from "../../../../.rest-hooks/types/predictions.yml";
import type { ActivePredictionState } from "../../ui/PredictionsProvider/PredictionsProvider";
import ManualPredictionGeometryByFileUrl from "../ManualPredictionGeometry/ManualPredictionGeometryByFileUrl";
import PotentialImpactsByPrediction from "../PotentialImpacts/PotentialImpactsByPrediction";
import PotentialImpactsCount from "../PotentialImpactsCount/PotentialImpactsCount";

interface ManualIncidentVisualisationProps {
  predictionAttributes: ManualIncidentPredictionAttributes;
  predictionId: string;
  state: ActivePredictionState;
}

const ManualIncidentVisualisation = ({
  predictionAttributes,
  predictionId,
  state,
}: ManualIncidentVisualisationProps) => {
  return (
    <>
      {predictionAttributes.result && (
        <ManualPredictionGeometryByFileUrl
          fileUrl={predictionAttributes.result.outputFileUrl}
        />
      )}
      {state.isImpactsActive && (
        <PotentialImpactsByPrediction
          opacity={state.opacity}
          predictionAttributes={predictionAttributes}
          showBurnProbability={false}
          predictionId={predictionId}
        />
      )}
      {state.isImpactsCountActive && (
        <PotentialImpactsCount predictionId={predictionId} />
      )}
    </>
  );
};

export default ManualIncidentVisualisation;

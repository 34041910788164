import { Button } from "@app/design-system";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";

const ResetLayersButton = () => {
  const { clearLayers } = useActiveLayersContext();

  return (
    <Button
      data-testid="resetLayers"
      fullWidth
      onClick={() => {
        clearLayers({
          source: "layers-drawer",
        });
      }}
    >
      Clear layers
    </Button>
  );
};

export default ResetLayersButton;

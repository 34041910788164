import { showToast } from "@app/design-system";
import { useEffect } from "react";
import { useGetIncidentsId } from "../../../../.rest-hooks/incidents";
import { selectIncidentPoint } from "./utils";

const useGetIncidentPoint = (incidentId: string | undefined) => {
  const result = useGetIncidentsId(incidentId!, {
    query: {
      enabled: !!incidentId,
      select: selectIncidentPoint,
    },
  });

  useEffect(() => {
    if (result.error) {
      showToast({
        variant: "error",
        title: "Unable to retrieve incident point",
      });
    }
  }, [result.error]);

  return result;
};

export default useGetIncidentPoint;

import useGeojsonFromFileUrl from "../../../hooks/useGeojsonFromFileUrl";
import useStableFileUrl from "../../../hooks/useStableFileUrl";
import { useFitToGeojson } from "../FitToGeojson/FitToGeojson";
import ManualPredictionGeometryLayer from "./ManualPredictionGeometryLayer";

type ManualPredictionGeometryByFileUrlProps = {
  fileUrl: string;
};

const ManualPredictionGeometryByFileUrl = (
  props: ManualPredictionGeometryByFileUrlProps,
) => {
  const fileUrl = useStableFileUrl(props.fileUrl);

  const { data: geojson, isError } = useGeojsonFromFileUrl({
    fileUrl,
    layerName: "manual prediction layer",
  });

  useFitToGeojson({
    geojson,
    id: "manualPrediction",
    isError,
  });

  if (!geojson || !fileUrl) {
    return null;
  }

  return <ManualPredictionGeometryLayer geojson={geojson} id={fileUrl} />;
};

export default ManualPredictionGeometryByFileUrl;

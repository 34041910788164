import { ModalOverlay, useIsMinWidth } from "@app/design-system";
import MapPopup, { type MapPopupProps, type MapPopupVariant } from "./MapPopup";
import MapPopupModal from "./MapPopupModal";

type MapPopupConfig = Omit<MapPopupProps, "children" | "onClose" | "lngLat">;

interface SpatialPopupProps {
  children: React.ReactNode;
  isDismissable?: boolean;
  isOpen: boolean;
  lngLat?: MapPopupProps["lngLat"] | undefined;
  popupConfig: MapPopupConfig;
  onClose: () => void;
  variant?: MapPopupVariant;
}

const SpatialPopup = ({
  children,
  isDismissable = true,
  lngLat,
  popupConfig,
  isOpen,
  onClose,
}: SpatialPopupProps) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  const isMapPopupModalOpen = isOpen && !isTabletLandscapeAndAbove;

  return (
    <>
      {isOpen && isTabletLandscapeAndAbove && lngLat && (
        <MapPopup
          {...popupConfig}
          key={popupConfig.id}
          lngLat={lngLat}
          onClose={onClose}
        >
          {children}
        </MapPopup>
      )}
      <ModalOverlay
        isDismissable={isDismissable}
        isOpen={isMapPopupModalOpen}
        onClose={onClose}
        size="xs"
      >
        <MapPopupModal isOpen={isMapPopupModalOpen}>{children}</MapPopupModal>
      </ModalOverlay>
    </>
  );
};

export default SpatialPopup;

import {
  AdjustedIndicator,
  buttonReset,
  focusStyles,
  media,
  Tooltip,
  TypographyIconWrapper,
  weakStyles,
} from "@app/design-system";
import styled, { css } from "styled-components";

export type DrawerHandleLayout = "compact" | "default";

interface StyledDrawerHandleProps {
  layout?: DrawerHandleLayout;
}

export const StyledDrawerHandle = styled.button<StyledDrawerHandleProps>`
  ${buttonReset}
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  ${(p) => p.theme.typography.variants.buttonLg};
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
  box-shadow: ${(p) => p.theme.boxShadows.md};
  transition: background-color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  &:is(button):hover:not(:disabled) {
    ${weakStyles.background.hover}
  }

  &:is(button):active:not(:disabled) {
    ${weakStyles.background.active}
  }

  &:is(button):focus-visible {
    ${focusStyles("ring")}
  }

  &:not(button) {
    cursor: default;
  }

  @media ${media.lg} {
    gap: 0.5rem;
    margin: 0;
    ${(p) =>
      p.layout === "compact"
        ? css`
            padding: 0.75rem;
          `
        : css`
            padding: 0.75rem 1.25rem 0.75rem 1rem;
          `}
    border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  }
`;

const StyledLabel = styled.div`
  display: flex;
  gap: 0.25rem;
`;

interface DrawerHandleProps {
  children?: React.ReactNode;
  icon: ReactSVGComponent;
  isAdjusted?: boolean;
  layout?: DrawerHandleLayout;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  "data-testid"?: string;
}

const DrawerHandle = ({
  children,
  icon,
  isAdjusted,
  layout = "default",
  onClick,
  "data-testid": dataTestId,
}: DrawerHandleProps) => {
  if (layout === "compact") {
    return (
      <Tooltip delay message={children}>
        <StyledDrawerHandle
          layout={layout}
          onClick={onClick}
          data-testid={dataTestId}
        >
          <TypographyIconWrapper icon={icon} size="buttonLg" />
          {isAdjusted && <AdjustedIndicator />}
        </StyledDrawerHandle>
      </Tooltip>
    );
  }
  return (
    <StyledDrawerHandle
      layout={layout}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <TypographyIconWrapper icon={icon} size="buttonDefault" />
      <StyledLabel>{children}</StyledLabel>
      {isAdjusted && <AdjustedIndicator />}
    </StyledDrawerHandle>
  );
};

export default DrawerHandle;

import { useGetPredictionsId } from "../../../../.rest-hooks/predictions";
import { potentialImpactsCounts } from "../../../config/layers/layers";
import getImpactCount from "../../../utils/getImpactCount/getImpactCount";
import {
  getTotalCriticalInfrastructureCount,
  getTotalHabitablePropertiesCount,
} from "../../../utils/getTotalImpactCount/getTotalImpactCount";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import {
  getHoursOffset,
  useTimeline,
} from "../../util/TimelineProvider/TimelineProvider";
import useGetIncidentPoint from "../IncidentPoint/useGetIncidentPoint";
import PotentialImpactsCountLayer from "./PotentialImpactsCountLayer";

interface PotentialImpactsCountProps {
  predictionId: string;
}

const PotentialImpactsCount = ({
  predictionId,
}: PotentialImpactsCountProps) => {
  const { data: predictionData } = useGetPredictionsId(predictionId);
  const prediction = predictionData?.data.data;
  const { data: geojson } = useGetIncidentPoint(
    prediction?.attributes.incidentId,
  );
  const { isLayerActive } = useActiveLayersContext();

  const {
    timelineState: { startDate },
  } = useTimeline();

  if (!prediction || !geojson || !isLayerActive(potentialImpactsCounts.id)) {
    return null;
  }

  const { impactError, impacts, validFrom } = prediction.attributes;

  const totalHabitablePropertiesCount =
    getTotalHabitablePropertiesCount({ impacts }) ?? 0;

  const totalCriticalInfrastructureCount =
    getTotalCriticalInfrastructureCount({ impacts }) ?? 0;

  const validFromMs = validFrom * 1000;

  const hoursFrom = getHoursOffset(startDate, validFromMs);
  const hoursTo = hoursFrom + 2;

  const criticalInfrastructureCount =
    getImpactCount({
      hourlyCount: impacts?.criticalInfrastructure.hourlyCount,
      hoursFrom,
      hoursTo,
    }) ?? 0;

  const habitablePropertiesCount =
    getImpactCount({
      hourlyCount: impacts?.habitableProperties.hourlyCount,
      hoursFrom,
      hoursTo,
    }) ?? 0;

  return (
    <PotentialImpactsCountLayer
      criticalInfrastructureCount={criticalInfrastructureCount}
      geojson={geojson}
      habitablePropertiesCount={habitablePropertiesCount}
      impactError={impactError}
      predictionId={prediction.id}
      totalCriticalInfrastructureCount={totalCriticalInfrastructureCount}
      totalHabitablePropertiesCount={totalHabitablePropertiesCount}
    />
  );
};

export default PotentialImpactsCount;

import { IconButton, LeftChevron, RightChevron } from "@app/design-system";
import styled from "styled-components";
import type { InteractionFeature } from "../../map/MapInteractions/types";

const StyledSpatialPopupPagination = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const StyledLabel = styled.div`
  ${(p) => p.theme.typography.variants.footnote}
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  white-space: nowrap;
`;

interface SpatialPopupPaginationProps {
  allFeatures: InteractionFeature[];
  featureId: string | number;
  onPaginate: (next: InteractionFeature) => void;
}

const SpatialPopupPagination = ({
  allFeatures,
  featureId,
  onPaginate,
}: SpatialPopupPaginationProps) => {
  const currentIndex = allFeatures.findIndex(
    (feature) => feature.properties.featureId === featureId,
  );

  return (
    <StyledSpatialPopupPagination>
      <IconButton
        disabled={currentIndex === 0}
        icon={LeftChevron}
        label="Previous"
        onClick={() => onPaginate(allFeatures[currentIndex - 1])}
        size="xs"
        variant="ghostWeak"
      />
      <StyledLabel>
        {currentIndex + 1} of {allFeatures?.length ?? 1}
      </StyledLabel>
      <IconButton
        disabled={currentIndex === allFeatures.length - 1}
        icon={RightChevron}
        label="Next"
        onClick={() => onPaginate(allFeatures[currentIndex + 1])}
        size="xs"
        variant="ghostWeak"
      />
    </StyledSpatialPopupPagination>
  );
};

export default SpatialPopupPagination;

import { useEffect } from "react";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import useMapContext from "../Map/useMapContext";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";
import { POTENTIAL_IMPACTS_SOURCE_ID } from "./constants";

const layerIdIconMap = {
  "abc-radio-towers": "/icons/marker-abc-radio-tower.png",
  "accommodation-facilities": "/icons/marker-accommodation-facilities.png",
  "airports-and-helipads": "/icons/marker-airports-and-helipads.png",
  "education-facilities": "/icons/marker-education-facilities.png",
  "grn-towers": "/icons/marker-grn.png",
  "health-facilities": "/icons/marker-health-facility.png",
  "pmr-towers": "/icons/marker-pmr-tower.png",
  "rfs-paging-towers": "/icons/marker-rfs-paging-tower.png",
  "transmissions-stations": "/icons/marker-transmission-stations.png",
  powerlines: "/icons/powerlines.png",
} as const;

interface UsePotentialImpactsMapDataParams {
  geojsonUrl: string;
  id: string;
}

const usePotentialImpactsMapData = ({
  geojsonUrl,
  id,
}: UsePotentialImpactsMapDataParams) => {
  const map = useMapContext();

  useEffect(() => {
    const controller = new AbortController();

    const loadIconsAndSetData = async () => {
      const images = Object.entries(layerIdIconMap);

      await Promise.all(
        images.map(([imageId, src]) =>
          loadImage({
            imageId,
            map,
            src,
            pixelRatio: 4,
            signal: controller.signal,
          }),
        ),
      );

      await loadImage({
        imageId: "label-bg",
        map,
        src: "/icons/label-bg.png",
        pixelRatio: 4,
        stretchX: [[28, 36]],
        stretchY: [[24, 32]],
        content: [20, 16, 44, 40],
        signal: controller.signal,
      });

      const source = map.getSource(`${POTENTIAL_IMPACTS_SOURCE_ID}-${id}`);
      if (isGeoJsonSource(source)) {
        source.setData(geojsonUrl);
      }
    };

    loadIconsAndSetData().catch(catchAbortError);

    return () => controller.abort();
  }, [geojsonUrl, id, map]);
};

export default usePotentialImpactsMapData;

import type { Prediction } from "../../../../.rest-hooks/types";
import { usePredictions } from "../../ui/PredictionsProvider/PredictionsProvider";
import PredictionVisualisation from "../PredictionVisualisation/PredictionVisualisation";

interface ActivePredictionsProps {
  filter?: (prediction: Prediction) => boolean;
}

const ActivePredictions = ({ filter }: ActivePredictionsProps) => {
  const {
    predictionsState: { activePredictions, predictions },
  } = usePredictions();

  return (
    <>
      {[...activePredictions.entries()].map(([predictionId, state]) => {
        const prediction = predictions.get(predictionId);
        if (!prediction) return null;
        if (filter && !filter(prediction)) return null;

        return (
          <PredictionVisualisation
            key={prediction.id}
            prediction={prediction}
            state={state}
          />
        );
      })}
    </>
  );
};

export default ActivePredictions;

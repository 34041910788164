import { FieldRow, Switch } from "@app/design-system";
import {
  fireFeatures,
  incidentIcons,
  potentialImpactsCounts,
  type LayerConfig,
} from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import LegendToggleButton from "./LegendToggleButton";

const currentOptionLayers: LayerConfig[] = [fireFeatures];
const phaseTwoOptionLayers: LayerConfig[] = [
  fireFeatures,
  incidentIcons,
  potentialImpactsCounts,
];

interface OptionLayersControllerProps {
  isMapRailEnabled?: boolean;
}

const OptionLayersController = ({
  isMapRailEnabled,
}: OptionLayersControllerProps) => {
  const { isLayerActive, activateLayer, deactivateLayer } =
    useActiveLayersContext();

  const onOptionLayerChange = (layer: LayerConfig) => (isSelected: boolean) => {
    if (isSelected) {
      activateLayer({
        id: layer.id,
        source: "option-layer",
      });
    } else {
      deactivateLayer({
        id: layer.id,
      });
    }
  };

  const optionLayers = isMapRailEnabled
    ? phaseTwoOptionLayers
    : currentOptionLayers;

  return (
    <>
      {optionLayers.map((layer) => (
        <FieldRow
          key={`${layer.id}-toggle-container`}
          id={`${layer.id}Label`}
          hint={layer.hint}
          htmlFor={`${layer.id}Switch`}
          // Adjust the first letter to be lowercase and add a "Show " prefix
          label={`Show ${(
            layer.label[0] || ""
          ).toLocaleLowerCase()}${layer.label.substring(1)}`}
        >
          {layer.legend && (
            <LegendToggleButton legend={layer.legend} variant="ghostWeak" />
          )}
          <Switch
            aria-labelledby={`${layer.id}Label`}
            isDisabled={layer.disabled}
            isSelected={isLayerActive(layer.id)}
            onChange={onOptionLayerChange(layer)}
            id={`${layer.id}Switch`}
            size="sm"
            data-testid={`${layer.id}-control`}
          />
        </FieldRow>
      ))}
    </>
  );
};

export default OptionLayersController;

import { FieldRow, Switch } from "@app/design-system";
import styled from "styled-components";
import {
  showMarkupLayer,
  type LayerConfig,
} from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";

const StyledShowMarkupController = styled.div`
  padding: 0.75rem 1rem 0.75rem 0.75rem;
  background: ${(p) => p.theme.colors.neutrals.surface};
  border-top-left-radius: ${(p) => p.theme.borderRadiuses.base}px;
  border-top-right-radius: ${(p) => p.theme.borderRadiuses.base}px;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.surfaceDivider};
`;

const ShowMarkupController = () => {
  const { activateLayer, deactivateLayer, isLayerActive } =
    useActiveLayersContext();

  const onLayerChange = (layer: LayerConfig) => (isSelected: boolean) => {
    if (isSelected) {
      activateLayer({
        id: layer.id,
        source: "option-layer",
      });
    } else {
      deactivateLayer({
        id: layer.id,
      });
    }
  };

  const isActive = isLayerActive(showMarkupLayer.id);

  return (
    <StyledShowMarkupController>
      <FieldRow htmlFor="showMarkupSwitch" label={showMarkupLayer.label}>
        <Switch
          aria-label={`Toggle ${showMarkupLayer.id} tool ${
            isActive ? "off" : "on"
          }`}
          id="showMarkupSwitch"
          isSelected={isActive}
          isDisabled={showMarkupLayer.disabled}
          key={showMarkupLayer.id}
          onChange={onLayerChange(showMarkupLayer)}
          size="sm"
          data-testid={`${showMarkupLayer.id}-control`}
        />
      </FieldRow>
    </StyledShowMarkupController>
  );
};

export default ShowMarkupController;

import DrawerContent from "../DrawerOverlay/DrawerContent";
import LegendList from "./LegendList";

const LegendDrawer = ({
  legendLayerSets,
}: React.ComponentProps<typeof LegendList>) => {
  return (
    <DrawerContent>
      <LegendList legendLayerSets={legendLayerSets} />
    </DrawerContent>
  );
};

export default LegendDrawer;

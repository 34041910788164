import { Mapping } from "@app/design-system";
import DrawerCell from "../DrawerOverlay/DrawerCell";
import type { DrawerHandleLayout } from "../DrawerOverlay/DrawerHandle";
import { DrawerOverlayGridArea } from "../DrawerOverlay/DrawerOverlayGrid";
import MapViewControls from "./MapViewControls";

interface MapDrawerCellProps {
  handleLayout?: DrawerHandleLayout;
}

const MapDrawerCell = ({ handleLayout }: MapDrawerCellProps) => {
  return (
    <DrawerCell
      gridArea={DrawerOverlayGridArea.MAP}
      handleLayout={handleLayout}
      icon={Mapping}
      label="Map"
      data-testid="map-drawer"
    >
      <MapViewControls />
    </DrawerCell>
  );
};

export default MapDrawerCell;

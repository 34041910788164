import { clamp } from "@kablamo/kerosene";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import useUnsafeMapContext from "../Map/useUnsafeMapContext";
import { isGeoJsonSource } from "../types";
import { loadIcon } from "../utils/loadImage";
import {
  CLUSTER_MAX_ZOOM,
  HABITABLE_PROPERTIES_ID,
  ICON_HOUSE,
  ICON_MULTI_HOUSE,
  MAX_HOURS,
} from "./constants";
import {
  useImpactedPropertyCentroids,
  usePredictionGeojson,
  useTotalImpactedBuildingCentroids,
} from "./hooks";
import { getZoomToClusterCallback } from "./utils";

interface Props {
  geojsonUrl?: string;
  id: string;
  hours: number;
  isManualPrediction: boolean;
  opacity: number;
}

export const PotentialImpactsHabitableProperties = ({
  geojsonUrl,
  id,
  hours,
  isManualPrediction,
  opacity,
}: Props) => {
  const { lib, map } = useUnsafeMapContext();
  if (!map) {
    throw new Error(
      "PotentialImpactsHabitableProperties useMapContext must be used within a Map child component",
    );
  }
  const clampedHours = clamp(0, hours, MAX_HOURS);
  const sourceId = `${HABITABLE_PROPERTIES_ID}-${id}`;
  const layerId = `${HABITABLE_PROPERTIES_ID}-${id}`;
  const clusterLayerId = `${layerId}-cluster`;

  const accessToken = useAuthAccessToken();
  const predictionFeatureCollection = usePredictionGeojson(geojsonUrl);
  const totalImpactedPropertyCentroids = useTotalImpactedBuildingCentroids(
    predictionFeatureCollection,
    accessToken,
    isManualPrediction,
  );
  const impactedPropertyCentroids = useImpactedPropertyCentroids(
    predictionFeatureCollection,
    totalImpactedPropertyCentroids,
    isManualPrediction,
    clampedHours,
  );

  useEffect(() => {
    const controller = new AbortController();

    const setPointer = () => {
      map.getCanvas().style.cursor = "pointer";
    };
    const removePointer = () => {
      map.getCanvas().style.cursor = "";
    };
    const zoomToCluster = getZoomToClusterCallback(
      map,
      sourceId,
      clusterLayerId,
    );
    map.addSource(sourceId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: true,
      clusterMaxZoom: CLUSTER_MAX_ZOOM,
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Promise.all(
      [ICON_HOUSE, ICON_MULTI_HOUSE].map((imageId) =>
        loadIcon({
          imageId,
          map,
          pixelRatio: 4,
        }),
      ),
    ).then(() => {
      if (controller.signal.aborted) return;

      map.addLayer(
        {
          id: layerId,
          type: "symbol",
          source: sourceId,
          layout: {
            "icon-image": ICON_HOUSE,
            "icon-size": 1,
            "icon-allow-overlap": true,
            "icon-anchor": "bottom",
          },
          filter: ["!=", "cluster", true],
        },
        MapLevel.SYMBOLS,
      );
      map.addLayer(
        {
          id: clusterLayerId,
          type: "symbol",
          source: sourceId,
          layout: {
            "icon-image": ICON_MULTI_HOUSE,
            "icon-size": 1,
            "icon-allow-overlap": true,
            "icon-anchor": "bottom",
          },
          filter: ["==", "cluster", true],
        },
        MapLevel.SYMBOLS,
      );

      // add cursor pointer to cluster layer

      // Note: Code duplication between Mapbox and MapLibre implementations is intentional here. TypeScript is confused
      // when combining overloads and this becomes type-unsafe if we suppress errors.
      if (lib === "mapbox") {
        map.on("mouseenter", clusterLayerId, setPointer);
        map.on("mouseleave", clusterLayerId, removePointer);
        map.on("click", clusterLayerId, zoomToCluster);
      } else {
        map.on("mouseenter", clusterLayerId, setPointer);
        map.on("mouseleave", clusterLayerId, removePointer);
        map.on("click", clusterLayerId, zoomToCluster);
      }
    });
    return () => {
      controller.abort();
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getLayer(clusterLayerId)) {
        map.removeLayer(clusterLayerId);
      }
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }

      // Note: Code duplication between Mapbox and MapLibre implementations is intentional here. TypeScript is confused
      // when combining overloads and this becomes type-unsafe if we suppress errors.
      if (lib === "mapbox") {
        map.off("click", clusterLayerId, zoomToCluster);
        map.off("mouseenter", clusterLayerId, setPointer);
        map.off("mouseleave", clusterLayerId, removePointer);
      } else {
        map.off("click", clusterLayerId, zoomToCluster);
        map.off("mouseenter", clusterLayerId, setPointer);
        map.off("mouseleave", clusterLayerId, removePointer);
      }
    };
  }, [clusterLayerId, id, layerId, lib, map, sourceId]);

  useEffect(() => {
    if (!impactedPropertyCentroids) return;
    const source = map.getSource(sourceId);
    if (!isGeoJsonSource(source)) return;
    source.setData(impactedPropertyCentroids);
  }, [impactedPropertyCentroids, map, sourceId]);

  useEffect(() => {
    // Map markers look "off" when they are semi-transparent but closer to 1,
    // especially by default (we use a 0.8 opacity as default for predictions).
    // If the user has selected an opacity less than 0.5, then we can infer that
    // they actually want to be able to see things behind the layer, so we use
    // that opacity. Otherwise, round it up to 1 so that they look solid.
    const clampedOpacity = opacity > 0.5 ? 1 : opacity;
    if (map.getLayer(layerId)) {
      map.setPaintProperty(layerId, "icon-opacity", clampedOpacity);
    }
    if (map.getLayer(clusterLayerId)) {
      map.setPaintProperty(clusterLayerId, "icon-opacity", clampedOpacity);
    }
  }, [opacity, map, layerId, clusterLayerId]);

  return null;
};

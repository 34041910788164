import DrawerContent from "../DrawerOverlay/DrawerContent";
import MarkupToolsetsList from "./MarkupToolsetsList";

const MarkupDrawer = () => {
  return (
    <DrawerContent>
      <MarkupToolsetsList data-testid="markup-toolsets-list" />
    </DrawerContent>
  );
};

export default MarkupDrawer;

import { bboxToTile, getChildren, tileToBBOX } from "@mapbox/tilebelt";
import { useEffect, useState } from "react";
import useUnsafeMapContext from "../../Map/useUnsafeMapContext";
import useMapBbox from "../useMapBbox/useMapBbox";

type TilesWithBboxes = Array<{ bbox: number[]; tile: number[] }>;
const MAX_ZOOM = 22;

export const useTiles = (): TilesWithBboxes => {
  const { map } = useUnsafeMapContext();
  const bbox = useMapBbox("geographic");
  const [tilesWithBboxes, setTilesWithBboxes] = useState<TilesWithBboxes>([]);

  useEffect(() => {
    if (!map || !bbox) return;
    const tile = bboxToTile(bbox);
    if (map.getZoom() === MAX_ZOOM) {
      setTilesWithBboxes([{ bbox: tileToBBOX(tile), tile }]);
    } else {
      const childTiles = getChildren(tile);
      const childBboxes = childTiles.map((childTile) => ({
        bbox: tileToBBOX(childTile),
        tile: childTile,
      }));
      setTilesWithBboxes(childBboxes);
    }
  }, [bbox, map]);

  return tilesWithBboxes;
};

import { useTheme } from "@app/design-system";
import fadeUpStyles from "@app/design-system/src/lib/styled/fadeUpStyles";
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const StyledMapPopupModal = styled.div`
  padding: 16px;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  box-shadow: ${(p) => p.theme.boxShadows.md};
  ${fadeUpStyles}
`;

export interface MapPopupModalProps {
  children?: React.ReactNode;
  isOpen: boolean;
  "data-testid"?: string;
}

const MapPopupModal = ({
  children,
  isOpen,
  "data-testid": dataTestId,
}: MapPopupModalProps) => {
  const theme = useTheme();

  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      appear
      in={isOpen}
      nodeRef={modalRef}
      timeout={theme.anim.duration.sm}
    >
      <StyledMapPopupModal
        ref={modalRef}
        data-testid={dataTestId && `${dataTestId}-modalPopup`}
      >
        {children}
      </StyledMapPopupModal>
    </CSSTransition>
  );
};

export default MapPopupModal;

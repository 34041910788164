import { Accordion, PilotLight } from "@app/design-system";
import styled from "styled-components";
import type { Tool } from "../../../config/tools";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";

const StyledMarkupToolsetsList = styled.div`
  display: grid;
  gap: 0.25rem;
  padding: 0.75rem;
`;
interface MarkupToolsetsListAccordionProps {
  title: string;
  tools: Tool[];
  "data-testid"?: string;
}

const MarkupToolsetsListAccordion = ({
  title,
  tools,
  "data-testid": dataTestId,
}: MarkupToolsetsListAccordionProps) => {
  const { isToolActive, visualiserDispatch } = useVisualiser();

  const onToolChange =
    (tool: Tool) => (event: React.ChangeEvent<HTMLInputElement>) => {
      visualiserDispatch({
        payload: event.currentTarget.checked ? tool : null,
        type: "setActiveTool",
      });
    };

  return (
    <Accordion title={title} data-testid={dataTestId}>
      <StyledMarkupToolsetsList>
        {tools.map((tool) => (
          <PilotLight
            checked={isToolActive(tool)}
            icon={tool.button.icon}
            key={tool.value}
            label={tool.button.label}
            onChange={onToolChange(tool)}
            data-testid={tool.value}
          />
        ))}
      </StyledMarkupToolsetsList>
    </Accordion>
  );
};

export default MarkupToolsetsListAccordion;

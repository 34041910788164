import { useIsMinWidth } from "@app/design-system";
import React, { useEffect } from "react";
import { useBoolean } from "usehooks-ts";
import makeTestId from "../../../utils/makeTestId";
import Drawer from "./Drawer";
import DrawerCellBase, { type DrawerCellAlignment } from "./DrawerCellBase";
import DrawerHandle, { type DrawerHandleLayout } from "./DrawerHandle";
import type { DrawerOverlayGridArea } from "./DrawerOverlayGrid";

interface DrawerCellProps {
  actions?: React.ReactNode;
  alignment?: DrawerCellAlignment;
  children?: React.ReactNode;
  defaultExpanded?: boolean;
  footer?: React.ReactNode;
  fullHeight?: boolean;
  gridArea: DrawerOverlayGridArea;
  handleLayout?: DrawerHandleLayout;
  icon: ReactSVGComponent;
  /**
   * If content in the drawer is adjusted, we can display an informative colored
   * indicator dot in the drawer handle.
   */
  isAdjusted?: boolean;
  isHidden?: boolean;
  label: React.ReactNode;
  "data-testid"?: string;
}

const DrawerCell = ({
  actions,
  alignment,
  children,
  defaultExpanded,
  footer,
  fullHeight,
  gridArea,
  handleLayout,
  icon,
  isAdjusted,
  isHidden,
  label,
  "data-testid": dataTestId,
}: DrawerCellProps) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  const shouldBeDefaultExpandedForViewport =
    !!isTabletLandscapeAndAbove && !!defaultExpanded;

  const {
    setFalse: close,
    setValue: setExpanded,
    toggle,
    value: isExpanded,
  } = useBoolean(shouldBeDefaultExpandedForViewport);

  // If defaultExpanded changes in response to viewport adjustments, update
  // expanded to match. This is in an effect so it only happens once and doesn't
  // prevent the user from expanding it in future
  useEffect(() => {
    setExpanded(shouldBeDefaultExpandedForViewport);
  }, [setExpanded, shouldBeDefaultExpandedForViewport]);

  // If isHidden is flipped to true and the drawer is currently expanded, close
  // it so that when isHidden becomes false, we default to a closed state
  if (isHidden && isExpanded && !shouldBeDefaultExpandedForViewport) {
    close();
  }

  return (
    <DrawerCellBase
      alignment={alignment}
      gridArea={gridArea}
      handle={
        <DrawerHandle
          icon={icon}
          isAdjusted={isAdjusted}
          layout={handleLayout}
          onClick={toggle}
          data-testid={makeTestId(dataTestId, "handle")}
        >
          {label}
        </DrawerHandle>
      }
      isExpanded={isExpanded}
      isHidden={isHidden}
    >
      <Drawer
        actions={actions}
        label={label}
        footer={footer}
        fullHeight={fullHeight}
        onClose={close}
        data-testid={dataTestId}
      >
        {children}
      </Drawer>
    </DrawerCellBase>
  );
};

export default DrawerCell;

import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useMapContext from "../Map/useMapContext";
import {
  POTENTIAL_IMPACTS_LABEL_LAYER_ID,
  POTENTIAL_IMPACTS_MARKER_LAYER_ID,
  POTENTIAL_IMPACTS_POWERLINES_LAYER_ID,
  POTENTIAL_IMPACTS_SOURCE_ID,
} from "./constants";

interface UsePotentialImpactsMapLayersParams {
  hours: number;
  id: string;
  opacity: number;
  showBurnProbability?: boolean;
}

const usePotentialImpactsMapLayers = ({
  hours,
  id,
  opacity,
  showBurnProbability = true,
}: UsePotentialImpactsMapLayersParams) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(`${POTENTIAL_IMPACTS_SOURCE_ID}-${id}`, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: `${POTENTIAL_IMPACTS_MARKER_LAYER_ID}-${id}`,
        type: "symbol",
        source: `${POTENTIAL_IMPACTS_SOURCE_ID}-${id}`,
        layout: {
          "icon-allow-overlap": true,
          "icon-anchor": "bottom",
        },
        filter: ["==", "$type", "Point"],
        minzoom: 8,
      },
      MapLevel.FS_SYMBOLS_PRIORITY,
    );

    if (showBurnProbability) {
      map.addLayer(
        {
          id: `${POTENTIAL_IMPACTS_LABEL_LAYER_ID}-${id}`,
          type: "symbol",
          source: `${POTENTIAL_IMPACTS_SOURCE_ID}-${id}`,
          layout: {
            "text-size": 14,
            "icon-text-fit": "both",
            "icon-text-fit-padding": [2, 6, 2, 6],
            "text-offset": [0, -7],
            "text-allow-overlap": false,
            "icon-allow-overlap": true,
            "text-padding": 0,
          },
          filter: ["==", "$type", "Point"],
          minzoom: 8,
        },
        MapLevel.FS_LABELS,
      );
    }

    map.addLayer(
      {
        id: `${POTENTIAL_IMPACTS_POWERLINES_LAYER_ID}-${id}`,
        type: "line",
        source: `${POTENTIAL_IMPACTS_SOURCE_ID}-${id}`,
        paint: {
          "line-color": "#000",
          "line-width": 10,
          "line-pattern": "powerlines",
          "line-opacity": 1,
        },

        filter: ["==", "$type", "LineString"],
        minzoom: 8,
      },
      MapLevel.LINES,
    );

    return () => {
      if (map.getLayer(`${POTENTIAL_IMPACTS_MARKER_LAYER_ID}-${id}`)) {
        map.removeLayer(`${POTENTIAL_IMPACTS_MARKER_LAYER_ID}-${id}`);
      }

      if (map.getLayer(`${POTENTIAL_IMPACTS_LABEL_LAYER_ID}-${id}`)) {
        map.removeLayer(`${POTENTIAL_IMPACTS_LABEL_LAYER_ID}-${id}`);
      }
      if (map.getLayer(`${POTENTIAL_IMPACTS_POWERLINES_LAYER_ID}-${id}`)) {
        map.removeLayer(`${POTENTIAL_IMPACTS_POWERLINES_LAYER_ID}-${id}`);
      }
      if (map.getSource(`${POTENTIAL_IMPACTS_SOURCE_ID}-${id}`)) {
        map.removeSource(`${POTENTIAL_IMPACTS_SOURCE_ID}-${id}`);
      }
    };
  }, [id, map, showBurnProbability]);

  // Update reactive layer properties
  useEffect(() => {
    map.setLayoutProperty(
      `${POTENTIAL_IMPACTS_MARKER_LAYER_ID}-${id}`,
      "icon-image",
      [
        "case",
        [">", ["at", hours, ["get", "burnProbabilities"]], 0],
        ["get", "LAYERID"],
        "",
      ],
    );

    if (showBurnProbability) {
      map.setLayoutProperty(
        `${POTENTIAL_IMPACTS_LABEL_LAYER_ID}-${id}`,
        "icon-image",
        [
          "case",
          [">", ["at", hours, ["get", "burnProbabilities"]], 0],
          "label-bg",
          "",
        ],
      );

      map.setLayoutProperty(
        `${POTENTIAL_IMPACTS_LABEL_LAYER_ID}-${id}`,
        "text-field",
        [
          "let",
          "burnProbability",
          ["at", hours, ["get", "burnProbabilities"]],
          [
            "case",
            ["==", ["typeof", ["var", "burnProbability"]], "number"],
            [
              "case",
              [">", ["var", "burnProbability"], 0],
              [
                "concat",
                ["round", ["*", 100, ["var", "burnProbability"]]],
                "%",
              ],
              "",
            ],
            "?%",
          ],
        ],
      );

      map.setLayoutProperty(
        `${POTENTIAL_IMPACTS_LABEL_LAYER_ID}-${id}`,
        "symbol-sort-key",
        ["-", ["at", hours, ["get", "burnProbabilities"]]],
      );
    }
  }, [hours, id, map, showBurnProbability]);

  useEffect(() => {
    map.setPaintProperty(
      `${POTENTIAL_IMPACTS_POWERLINES_LAYER_ID}-${id}`,
      "line-opacity",
      opacity,
    );

    map.setPaintProperty(
      `${POTENTIAL_IMPACTS_MARKER_LAYER_ID}-${id}`,
      "icon-opacity",
      opacity,
    );

    map.setPaintProperty(
      `${POTENTIAL_IMPACTS_LABEL_LAYER_ID}-${id}`,
      "text-opacity",
      opacity,
    );

    map.setPaintProperty(
      `${POTENTIAL_IMPACTS_LABEL_LAYER_ID}-${id}`,
      "icon-opacity",
      opacity,
    );
  }, [opacity, map, id]);
};

export default usePotentialImpactsMapLayers;

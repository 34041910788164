import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import type { IncidentPointFeature } from "../IncidentPoint/utils";
import useMapContext from "../Map/useMapContext";
import { isGeoJsonSource } from "../types";
import { loadIcon } from "../utils/loadImage";

interface PotentialImpactsCountLayerProps {
  criticalInfrastructureCount: number;
  geojson: IncidentPointFeature;
  habitablePropertiesCount: number;
  impactError?: string;
  predictionId: string;
  totalCriticalInfrastructureCount: number;
  totalHabitablePropertiesCount: number;
}

const PotentialImpactsCountLayer = ({
  criticalInfrastructureCount,
  geojson,
  habitablePropertiesCount,
  impactError,
  predictionId,
  totalCriticalInfrastructureCount,
  totalHabitablePropertiesCount,
}: PotentialImpactsCountLayerProps) => {
  const map = useMapContext();

  const sourceId = `${predictionId}-impacts-count`;
  const layerId = `${predictionId}-impacts-count-marker`;

  useEffect(() => {
    if (!map) return;

    map.addSource(sourceId, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
    });

    loadIcon({
      imageId: "label-bg",
      map,
      pixelRatio: 4,
      stretchX: [[28, 36]],
      stretchY: [[24, 32]],
      content: [20, 16, 44, 40],
    }).catch(catchAbortError);

    map.addLayer(
      {
        id: layerId,
        type: "symbol",
        source: sourceId,
        layout: {
          "icon-image": "label-bg",
          "icon-allow-overlap": true,
          "icon-text-fit": "both",
          "icon-text-fit-padding": [6, 8, 6, 8],
          "text-allow-overlap": true,
          "text-size": 14,
          "text-offset": [0, 4],
          "text-justify": "left",
          "text-max-width": 200,
        },
      },
      MapLevel.FS_LABELS,
    );

    return () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    };
  }, [layerId, map, sourceId]);

  useEffect(() => {
    const source = map.getSource(sourceId);
    if (isGeoJsonSource(source)) {
      source.setData(geojson);
    }
  }, [geojson, map, sourceId]);

  useEffect(() => {
    if (!map || !map.getLayer(layerId)) return;

    if (impactError) {
      map.setLayoutProperty(layerId, "text-field", "Error calculating impacts");
      return;
    }

    const bullet = "\u{2022}";

    map.setLayoutProperty(layerId, "text-field", [
      "concat",
      `Next 2hrs${"  "}${bullet}${"  "}HP: ${habitablePropertiesCount}${"  "}${bullet}${"  "}CI: ${criticalInfrastructureCount}`,
      "\n",
      `Total${"  "}${bullet}${"  "}HP: ${totalHabitablePropertiesCount}${"  "}${bullet}${"  "}CI: ${totalCriticalInfrastructureCount} `,
    ]);
  }, [
    criticalInfrastructureCount,
    habitablePropertiesCount,
    impactError,
    layerId,
    map,
    totalCriticalInfrastructureCount,
    totalHabitablePropertiesCount,
  ]);

  return null;
};

export default PotentialImpactsCountLayer;

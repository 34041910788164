import type { RapidAppreciationPredictionAttributes } from "../../../../.rest-hooks/types/predictions.yml";
import type { ActivePredictionState } from "../../ui/PredictionsProvider/PredictionsProvider";
import PotentialImpactsByPrediction from "../PotentialImpacts/PotentialImpactsByPrediction";
import PotentialImpactsCount from "../PotentialImpactsCount/PotentialImpactsCount";
import BurnProbabilityByPrediction from "../PredictionOutputs/BurnProbability/BurnProbabilityByPrediction";
import EmberDensityByPrediction from "../PredictionOutputs/EmberDensity/EmberDensityByPrediction";
import FlameHeightByPrediction from "../PredictionOutputs/FlameHeight/FlameHeightByPrediction";

interface RapidAppreciationVisualisationProps {
  predictionId: string;
  predictionAttributes: RapidAppreciationPredictionAttributes;
  state: ActivePredictionState;
}

const RapidAppreciationVisualisation = ({
  predictionAttributes,
  predictionId,
  state,
}: RapidAppreciationVisualisationProps) => {
  return (
    <>
      {state.output === "burnProbability" && (
        <BurnProbabilityByPrediction
          opacity={state.opacity}
          predictionAttributes={predictionAttributes}
          predictionId={predictionId}
        />
      )}
      {state.output === "flameHeight" && (
        <FlameHeightByPrediction
          opacity={state.opacity}
          predictionAttributes={predictionAttributes}
          predictionId={predictionId}
        />
      )}
      {state.isEmberDensityActive && (
        <EmberDensityByPrediction
          opacity={state.opacity}
          predictionAttributes={predictionAttributes}
          predictionId={predictionId}
        />
      )}
      {state.isImpactsActive && (
        <PotentialImpactsByPrediction
          opacity={state.opacity}
          predictionAttributes={predictionAttributes}
          predictionId={predictionId}
        />
      )}
      {state.isImpactsCountActive && (
        <PotentialImpactsCount predictionId={predictionId} />
      )}
    </>
  );
};

export default RapidAppreciationVisualisation;

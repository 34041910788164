import { media, useTheme } from "@app/design-system";
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";
import { StyledDrawerHandle } from "./DrawerHandle";
import { DrawerOverlayGridArea } from "./DrawerOverlayGrid";

export type DrawerCellAlignment = "start" | "center" | "end";

interface StyledDrawerCellProps {
  alignment?: DrawerCellAlignment;
  gridArea?: DrawerOverlayGridArea;
  isDrawer?: boolean;
}

export const drawerTransitionStyles = css`
  &.enter {
    opacity: 0;
    transform: scale(0.98);
  }

  &.enter-active {
    opacity: 1;
    transform: scale(1);
    transition:
      opacity
        ${(p) =>
          `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve} ${p.theme.anim.duration.sm}ms`},
      transform
        ${(p) =>
          `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve} ${p.theme.anim.duration.sm}ms`};
  }

  &.exit {
    opacity: 1;
    transform: scale(1);
  }

  &.exit-active {
    opacity: 0;
    transform: scale(0.98);
    transition:
      opacity ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
      transform ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }

  &.exit,
  &.exit-active {
    ${StyledDrawerHandle} {
      background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
    }
  }

  &.exit-done {
    display: none;
  }
`;

const contentCellStyles = css<StyledDrawerCellProps>`
  position: relative;
  display: flex;
  align-items: start;
  grid-area: ${(p) => p.gridArea};
`;

const nonContentCellStyles = css`
  display: none;
`;

const commonStyles = css<StyledDrawerCellProps>`
  ${(p) =>
    p.gridArea === DrawerOverlayGridArea.CONTENT
      ? contentCellStyles
      : nonContentCellStyles}
  justify-content: center;

  @media ${media.lg} {
    ${contentCellStyles}
    justify-content: ${(p) => {
      switch (p.alignment) {
        case "center": {
          return "center";
        }
        case "end": {
          return "end";
        }
        case "start":
        default: {
          return "normal";
        }
      }
    }};
  }

  & > * {
    pointer-events: all;
  }

  ${drawerTransitionStyles}
`;

const StyledDrawerHandleCell = styled.div<StyledDrawerCellProps>`
  ${commonStyles}
  z-index: ${(p) => p.theme.zIndexes.bottomSheet - 1};

  @media ${media.lg} {
    z-index: ${(p) => p.theme.zIndexes.overlayDrawer};
  }
`;

const StyledDrawerCell = styled.div<StyledDrawerCellProps>`
  ${commonStyles}
  z-index: ${(p) => p.theme.zIndexes.overlayDrawer};
`;

interface DrawerCellBaseProps {
  alignment?: DrawerCellAlignment;
  children?: React.ReactNode;
  gridArea?: DrawerOverlayGridArea;
  handle?: React.ReactNode;
  isExpanded: boolean;
  isHidden?: boolean;
}

const DrawerCellBase = ({
  alignment = "start",
  children,
  gridArea,
  handle,
  isExpanded,
  isHidden,
}: DrawerCellBaseProps) => {
  const theme = useTheme();

  const drawerCellRef = useRef<HTMLDivElement>(null);
  const drawerHandleCellRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <CSSTransition
        in={!isExpanded && !isHidden}
        mountOnEnter
        nodeRef={drawerHandleCellRef}
        timeout={{
          enter: theme.anim.duration.sm * 2,
          exit: theme.anim.duration.sm,
        }}
      >
        <StyledDrawerHandleCell
          alignment={alignment}
          gridArea={gridArea}
          ref={drawerHandleCellRef}
        >
          {handle}
        </StyledDrawerHandleCell>
      </CSSTransition>
      <CSSTransition
        in={isExpanded && !isHidden}
        mountOnEnter
        nodeRef={drawerCellRef}
        timeout={{
          enter: theme.anim.duration.sm * 2,
          exit: theme.anim.duration.sm,
        }}
      >
        <StyledDrawerCell
          alignment={alignment}
          ref={drawerCellRef}
          gridArea={gridArea}
          isDrawer
        >
          {children}
        </StyledDrawerCell>
      </CSSTransition>
    </>
  );
};

export default DrawerCellBase;

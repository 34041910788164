import type { ManualRedMapPredictionAttributes } from "../../../../.rest-hooks/types/predictions.yml";
import type { ActivePredictionState } from "../../ui/PredictionsProvider/PredictionsProvider";
import RedMapByFileUrl from "../RedMap/RedMapByPrediction";

interface ManualRedMapVisualisationProps {
  predictionAttributes: ManualRedMapPredictionAttributes;
  predictionId: string;
  state: ActivePredictionState;
}

const ManualRedMapVisualisation = ({
  predictionAttributes,
  predictionId,
  state,
}: ManualRedMapVisualisationProps) => {
  return (
    <RedMapByFileUrl
      id={predictionId}
      opacity={state.opacity}
      potentialEmbersFileUrl={
        predictionAttributes.result?.potentialEmbersFileUrl
      }
      potentialSpreadFileUrl={
        predictionAttributes.result?.potentialSpreadFileUrl
      }
    />
  );
};

export default ManualRedMapVisualisation;

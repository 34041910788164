import type { Prediction } from "../../../../.rest-hooks/types";
import {
  isManualUploadPredictionAttributes,
  isRapidAppreciationPredictionAttributes,
  isRedMapPredictionAttributes,
} from "../../../config/predictionProduct";
import type { ActivePredictionState } from "../../ui/PredictionsProvider/PredictionsProvider";
import ManualIncidentVisualisation from "./ManualIncidentVisualisation";
import ManualRedMapVisualisation from "./ManualRedMapVisualisation";
import RapidAppreciationVisualisation from "./RapidAppreciationVisualisation";

interface PredictionVisualisationProps {
  prediction: Prediction | undefined;
  state: ActivePredictionState;
}

const PredictionVisualisation = ({
  prediction,
  state,
}: PredictionVisualisationProps) => {
  if (!prediction) {
    return null;
  }

  if (!prediction.attributes.result) {
    return null;
  }

  if (isManualUploadPredictionAttributes(prediction.attributes)) {
    return (
      <ManualIncidentVisualisation
        predictionAttributes={prediction.attributes}
        predictionId={prediction.id}
        state={state}
      />
    );
  }

  if (isRedMapPredictionAttributes(prediction.attributes)) {
    return (
      <ManualRedMapVisualisation
        predictionAttributes={prediction.attributes}
        predictionId={prediction.id}
        state={state}
      />
    );
  }

  if (isRapidAppreciationPredictionAttributes(prediction.attributes)) {
    return (
      <RapidAppreciationVisualisation
        predictionAttributes={prediction.attributes}
        predictionId={prediction.id}
        state={state}
      />
    );
  }

  return null;
};

export default PredictionVisualisation;

import {
  CollapsibleMenuSection,
  FieldGroup,
  FieldRow,
  MenuSection,
  RadioButton,
  Switch,
  Tooltip,
  focusStyles,
} from "@app/design-system";
import StyledHiddenInput from "@app/design-system/src/components/StyledHiddenInput/StyledHiddenInput";
import React, { forwardRef } from "react";
import styled from "styled-components";
import {
  baseMapLayers,
  type BaseLayer,
} from "../../../../config/layers/baseLayers";
import {
  terrainLayer,
  type LayerConfig,
} from "../../../../config/layers/layers";
import OptionLayersController from "../../../drawers/LayersDrawerCell/OptionLayersController";
import { useActiveLayersContext } from "../../../util/ActiveLayersProvider/ActiveLayersProvider";
import { useVisualiser } from "../../Visualiser/VisualiserProvider";

const StyledGrid = styled.div`
  display: grid;
  padding-top: 0.25rem;
  gap: 0.75rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
`;

const StyledImage = styled.img`
  display: block;
  width: 100%;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};
  transition: border-color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
`;

const StyledInner = styled.div`
  position: absolute;
  inset: 0;
  padding: 0.375rem;

  ${StyledHiddenInput}:focus-visible ~ &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    ${focusStyles("ring")}
    border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  }

  ${StyledHiddenInput}:checked ~ & {
    position: absolute;
    inset: 0;
    border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
    outline: 2px solid ${(p) => p.theme.colors.neutrals.borderSelected};
    outline-offset: 2px;

    ${RadioButton.StyledUnchecked} {
      display: none;
    }
  }

  ${StyledHiddenInput}:not(:checked) ~ & {
    ${RadioButton.StyledChecked} {
      display: none;
    }
  }

  &:hover {
    ${RadioButton.StyledUnchecked},
    ${RadioButton.StyledChecked} {
      background-color: ${(p) => p.theme.colors.neutrals.backgroundMediumHover};
    }

    ${RadioButton.StyledUnchecked} {
      border-color: ${(p) => p.theme.colors.neutrals.borderMediumHover};
    }
  }

  ${StyledHiddenInput}:active ~ & {
    ${RadioButton.StyledUnchecked} {
      background-color: ${(p) =>
        p.theme.colors.neutrals.backgroundMediumActive};
      border-color: ${(p) => p.theme.colors.neutrals.borderMediumActive};
    }
  }

  ${StyledHiddenInput}:active ~ & {
    ${RadioButton.StyledUnchecked} {
      background-color: ${(p) =>
        p.theme.colors.neutrals.backgroundMediumActive};
      border-color: ${(p) => p.theme.colors.neutrals.borderMediumActive};
    }
  }
`;

const StyledControl = styled.div`
  width: 1rem;
  height: 1rem;
`;

const StyledBaseMapRadioButton = styled.label`
  position: relative;
  display: block;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  cursor: pointer;

  &:hover ${StyledImage} {
    border-color: ${(p) => p.theme.colors.neutrals.borderWeakHover};
  }

  ${StyledHiddenInput}:active ~ ${StyledImage} {
    border-color: ${(p) => p.theme.colors.neutrals.borderWeakActive};
  }
`;

interface BaseMapRadioButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  src: string;
}

const BaseMapRadioButton = forwardRef<
  HTMLLabelElement,
  BaseMapRadioButtonProps
>(({ src, ...props }: BaseMapRadioButtonProps, ref) => {
  return (
    <StyledBaseMapRadioButton ref={ref}>
      <StyledHiddenInput {...props} type="radio" />
      <StyledImage aria-hidden src={src} />
      <StyledInner>
        <StyledControl>
          <RadioButton.StyledChecked />
          <RadioButton.StyledUnchecked />
        </StyledControl>
      </StyledInner>
    </StyledBaseMapRadioButton>
  );
});

const LayersDrawerHeader = () => {
  const { is3DEnabled } = useVisualiser();

  const { isLayerActive, activateLayer, deactivateLayer, setBaseMap } =
    useActiveLayersContext();

  const onBaseMapChange = (layer: BaseLayer) => () => {
    setBaseMap({
      id: layer.id,
    });
  };

  const onLayerChange = (layer: LayerConfig) => (isSelected: boolean) => {
    if (isSelected) {
      activateLayer({
        id: layer.id,
        source: "option-layer",
      });
    } else {
      deactivateLayer({ id: layer.id });
    }
  };

  return (
    <FieldGroup>
      <MenuSection label="Base map">
        <StyledGrid>
          {baseMapLayers.map((layer) => {
            return (
              <Tooltip delay key={layer.id} message={layer.label}>
                <BaseMapRadioButton
                  data-testid={`basemap-${layer.id.toLowerCase()}`}
                  aria-label={layer.label}
                  checked={isLayerActive(layer.id)}
                  onChange={onBaseMapChange(layer)}
                  name="basemap"
                  src={layer.imageSrc}
                  value={layer.id}
                />
              </Tooltip>
            );
          })}
        </StyledGrid>
      </MenuSection>
      <CollapsibleMenuSection label="Options">
        <FieldGroup>
          {is3DEnabled && (
            <FieldRow
              htmlFor="enableTerrainSwitch"
              id="enableTerrainSwitchLabel"
              label="Enable 3D"
            >
              <Switch
                aria-labelledby="enableTerrainSwitchLabel"
                id="enableTerrainSwitch"
                isSelected={isLayerActive(terrainLayer.id)}
                isDisabled={terrainLayer.disabled}
                key={terrainLayer.id}
                onChange={onLayerChange(terrainLayer)}
                size="sm"
                data-testid={`${terrainLayer.id}-checkbox`}
              />
            </FieldRow>
          )}
          <OptionLayersController isMapRailEnabled />
        </FieldGroup>
      </CollapsibleMenuSection>
    </FieldGroup>
  );
};

export default LayersDrawerHeader;

import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import useUnsafeMapContext from "../../Map/useUnsafeMapContext";

const getGeographicBbox = (
  map: mapboxgl.Map | maplibregl.Map | null,
): number[] | null => {
  return map?.getBounds().toArray().flat() ?? null;
};

interface CartesianBbox {
  maxX: number;
  maxY: number;
  minX: number;
  minY: number;
}

const getCartesianBbox = (
  map: mapboxgl.Map | maplibregl.Map | null,
): CartesianBbox | null => {
  const geographicBbox = getGeographicBbox(map);

  if (!geographicBbox) return null;

  const [minX, minY, maxX, maxY] = geographicBbox;

  return {
    maxX,
    maxY,
    minX,
    minY,
  };
};

const DEBOUNCE_MS = 1000;

type BboxFormat = "cartesian" | "geographic";

export interface UseMapBboxParams {
  debounced?: boolean;
}

/**
 * Returns a map bounding box in the format { minX, minY, maxX, maxY }
 */
function useMapBbox(
  format: "cartesian",
  options?: UseMapBboxParams,
): CartesianBbox | null;
/**
 * Returns map bounding box in an array format [number, number, number, number]
 */
function useMapBbox(
  format: "geographic",
  options?: UseMapBboxParams,
): number[] | null;
function useMapBbox(
  format: BboxFormat,
  { debounced }: UseMapBboxParams = {},
): CartesianBbox | number[] | null {
  const { map } = useUnsafeMapContext();
  const [bbox, setBbox] = useState(() =>
    format === "cartesian" ? getCartesianBbox(map) : getGeographicBbox(map),
  );

  useEffect(() => {
    if (!map) return;

    function updateBbox() {
      setBbox(
        format === "cartesian" ? getCartesianBbox(map) : getGeographicBbox(map),
      );
    }

    updateBbox();

    const debouncedUpdateBbox = debounce(
      () => updateBbox(),
      debounced ? DEBOUNCE_MS : 0,
      {
        leading: true,
      },
    );

    map.on("moveend", debouncedUpdateBbox);

    return () => {
      map.off("moveend", debouncedUpdateBbox);
    };
  }, [debounced, format, map]);

  return bbox;
}

export default useMapBbox;

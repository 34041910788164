import {
  Info,
  InfoFilled,
  ToggleIconButton,
  type IconButtonSize,
  type IconButtonVariant,
} from "@app/design-system";
import type { Legend } from "../../../config/layers/layers";
import { useMapRailContext } from "../../ui/MapRail/MapRailProvider";

interface LegendToggleButtonProps {
  legend: Legend;
  size?: IconButtonSize;
  variant?: IconButtonVariant;
}

const LegendToggleButton = ({
  legend,
  size = "xs",
  variant = "ghost",
}: LegendToggleButtonProps) => {
  const { pushModal, removeModal, selectedModalItems } = useMapRailContext();

  const isSelected = selectedModalItems.get("legend")?.props.id === legend.id;

  return (
    <ToggleIconButton
      iconOn={InfoFilled}
      iconOff={Info}
      label="Legend"
      onClick={() => {
        if (isSelected) {
          removeModal({ id: "legend" });
        } else {
          pushModal({
            item: {
              id: "legend",
              props: {
                component: legend.component,
                id: legend.id,
                label: legend.label,
              },
              type: "modal",
            },
          });
        }
      }}
      selected={isSelected}
      size={size}
      variant={variant}
    />
  );
};

export default LegendToggleButton;

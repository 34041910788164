import { useTheme } from "@app/design-system";
import type { FeatureCollection } from "geojson";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import useMapContext from "../Map/useMapContext";
import { isGeoJsonSource } from "../types";
import { loadIcon } from "../utils/loadImage";

export type RedMapLayerVariant = "default" | "weak";

interface RedMapLayerProps {
  id: string;
  opacity?: number;
  potentialEmbersGeojson: FeatureCollection;
  potentialSpreadGeojson: FeatureCollection;
  variant?: RedMapLayerVariant;
}

const SPOT_FIRE_IMAGE_ID = "spot-fire";

const FILL_OPACITY = 0.8;
const WEAK_FILL_OPACITY = 0.5;

const LINE_OPACITY = 1;
const WEAK_LINE_OPACITY = 0.7;

const RedMapLayer = ({
  id,
  opacity = 1,
  potentialEmbersGeojson,
  potentialSpreadGeojson,
  variant = "default",
}: RedMapLayerProps) => {
  const map = useMapContext();
  const theme = useTheme();
  const spreadLineColor = theme.colors.mapping.redMap;
  const spreadFillColor = theme.colors.mapping.redMap;

  const EMBERS_SOURCE_ID = `${id}-embers`;
  const SPREAD_SOURCE_ID = `${id}-spread`;

  const RED_MAP_LINE_LAYER_ID = `${id}-red-map-line`;
  const RED_MAP_FILL_LAYER_ID = `${id}-red-map-fill`;
  const RED_MAP_PATTERN_LAYER_ID = `${id}-red-map-pattern`;

  const isWeak = variant === "weak";

  useEffect(() => {
    map.addSource(EMBERS_SOURCE_ID, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
    });
    map.addSource(SPREAD_SOURCE_ID, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
    });

    loadIcon({
      imageId: SPOT_FIRE_IMAGE_ID,
      map,
      pixelRatio: 4,
    }).catch(catchAbortError);
    map.addLayer(
      {
        id: RED_MAP_LINE_LAYER_ID,
        type: "line",
        source: SPREAD_SOURCE_ID,
        paint: {
          "line-color": spreadLineColor,
          "line-width": 1,
        },
      },
      MapLevel.LINES,
    );

    map.addLayer(
      {
        id: RED_MAP_FILL_LAYER_ID,
        type: "fill",
        source: SPREAD_SOURCE_ID,
        paint: {
          "fill-color": spreadFillColor,
        },
      },
      MapLevel.LINES,
    );

    map.addLayer(
      {
        id: RED_MAP_PATTERN_LAYER_ID,
        type: "fill",
        source: EMBERS_SOURCE_ID,
        paint: {
          "fill-pattern": SPOT_FIRE_IMAGE_ID,
        },
      },
      MapLevel.LINES,
    );

    return () => {
      if (map.getLayer(RED_MAP_LINE_LAYER_ID)) {
        map.removeLayer(RED_MAP_LINE_LAYER_ID);
      }
      if (map.getLayer(RED_MAP_FILL_LAYER_ID)) {
        map.removeLayer(RED_MAP_FILL_LAYER_ID);
      }
      if (map.getLayer(RED_MAP_PATTERN_LAYER_ID)) {
        map.removeLayer(RED_MAP_PATTERN_LAYER_ID);
      }
      if (map.getSource(EMBERS_SOURCE_ID)) {
        map.removeSource(EMBERS_SOURCE_ID);
      }
      if (map.getSource(SPREAD_SOURCE_ID)) {
        map.removeSource(SPREAD_SOURCE_ID);
      }
    };
  }, [
    EMBERS_SOURCE_ID,
    RED_MAP_FILL_LAYER_ID,
    RED_MAP_LINE_LAYER_ID,
    RED_MAP_PATTERN_LAYER_ID,
    SPREAD_SOURCE_ID,
    map,
    spreadFillColor,
    spreadLineColor,
  ]);

  useEffect(() => {
    const fillOpacity = isWeak ? WEAK_FILL_OPACITY : FILL_OPACITY;
    const clampedFillOpacity =
      opacity > 0.5 ? fillOpacity : fillOpacity * opacity;

    const lineOpacity = isWeak ? WEAK_LINE_OPACITY : LINE_OPACITY;
    const clampedLineOpacity =
      opacity > 0.5 ? lineOpacity : lineOpacity * opacity;

    map.setPaintProperty(
      RED_MAP_LINE_LAYER_ID,
      "line-opacity",
      clampedLineOpacity,
    );
    map.setPaintProperty(
      RED_MAP_FILL_LAYER_ID,
      "fill-opacity",
      clampedFillOpacity,
    );
    map.setPaintProperty(
      RED_MAP_PATTERN_LAYER_ID,
      "fill-opacity",
      clampedFillOpacity,
    );
  }, [
    RED_MAP_FILL_LAYER_ID,
    RED_MAP_LINE_LAYER_ID,
    RED_MAP_PATTERN_LAYER_ID,
    isWeak,
    map,
    opacity,
  ]);

  useEffect(() => {
    const source = map.getSource(EMBERS_SOURCE_ID);
    if (isGeoJsonSource(source)) {
      source.setData(potentialEmbersGeojson);
    }
  }, [EMBERS_SOURCE_ID, map, potentialEmbersGeojson]);

  useEffect(() => {
    const source = map.getSource(SPREAD_SOURCE_ID);
    if (isGeoJsonSource(source)) {
      source.setData(potentialSpreadGeojson);
    }
  }, [SPREAD_SOURCE_ID, map, potentialSpreadGeojson]);

  return null;
};

export default RedMapLayer;

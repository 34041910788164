import { showToast } from "@app/design-system/src";
import { geojsonToArcGIS } from "@esri/arcgis-to-geojson-utils";
import * as Sentry from "@sentry/nextjs";
import booleanContains from "@turf/boolean-contains";
import { featureCollection } from "@turf/helpers";
import type * as ArcGIS from "arcgis-rest-api";
import type { FeatureCollection, Point, Polygon } from "geojson";
import { useEffect, useState } from "react";
import dedupeArray from "../../../utils/dedupeArray/dedupeArray";
import { MAX_HOURS } from "./constants";
import {
  extractFeaturesFromFeatureCollections,
  fetchBuildingsInPolygons,
  getOutlinePolygon,
} from "./utils";

export function usePredictionGeojson(geojsonUrl: string | undefined) {
  const [predictionFeatureCollection, setPredictionFeatureCollection] =
    useState<FeatureCollection<Polygon> | null>(null);
  useEffect(() => {
    if (!geojsonUrl) return;
    const abortController = new AbortController();
    fetch(geojsonUrl, {
      signal: abortController.signal,
    })
      .then((response) => response.json())
      .then((data) => {
        setPredictionFeatureCollection(data);
      })
      .catch((error) => {
        if (error.name === "AbortError") return;
        showToast({
          id: "habitable-properties-prediction-loading-error",
          title: "Error loading prediction",
          message: "An error occurred loading the prediction data",
          variant: "error",
        });
        Sentry.captureException(error);
      });
    return () => {
      abortController.abort();
    };
  }, [geojsonUrl, setPredictionFeatureCollection]);
  return predictionFeatureCollection;
}

export function useTotalImpactedBuildingCentroids(
  predictionFeatureCollection: FeatureCollection<Polygon> | null,
  accessToken: string | undefined,
  isManualPrediction: boolean,
) {
  const [totalImpactedPropertyCentroids, setTotalImpactedPropertyCentroids] =
    useState<FeatureCollection<Point> | null>(null);
  useEffect(() => {
    if (!predictionFeatureCollection || !accessToken) return;
    const outline = getOutlinePolygon(
      isManualPrediction,
      predictionFeatureCollection,
      MAX_HOURS,
    );
    const esriPolygons = geojsonToArcGIS(outline) as Array<
      ArcGIS.Feature & { geometry: ArcGIS.Polygon }
    >;
    const abortController = new AbortController();
    const promises = esriPolygons.map(
      fetchBuildingsInPolygons(abortController, accessToken),
    );
    Promise.all(promises)
      .then((results: FeatureCollection[]) => {
        const allFeatures = extractFeaturesFromFeatureCollections(results);
        setTotalImpactedPropertyCentroids({
          type: "FeatureCollection",
          features: dedupeArray(allFeatures),
        });
      })
      .catch((error) => {
        if (error.name === "AbortError") return;
        Sentry.captureException(error);
        showToast({
          id: "habitable-properties-error",
          title: "Habitable Properties not loaded",
          message:
            "There was an error loading the potentially impacted habitable properties for this prediction. Please try again.",
          variant: "error",
        });
      });
    return () => {
      abortController.abort();
    };
  }, [
    predictionFeatureCollection,
    accessToken,
    isManualPrediction,
    setTotalImpactedPropertyCentroids,
  ]);
  return totalImpactedPropertyCentroids;
}

export function useImpactedPropertyCentroids(
  predictionFeatureCollection: FeatureCollection<Polygon> | null,
  totalImpactedPropertyCentroids: FeatureCollection<Point> | null,
  isManualPrediction: boolean,
  clampedHours: number,
) {
  const [impactedPropertyCentroids, setImpactedPropertyCentroids] =
    useState<FeatureCollection<Point> | null>(null);
  useEffect(() => {
    if (!predictionFeatureCollection || !totalImpactedPropertyCentroids) return;

    const outline = getOutlinePolygon(
      isManualPrediction,
      predictionFeatureCollection,
      clampedHours,
    );

    const intersectingFeatures = outline.features
      .map((outlineFeature) => {
        return totalImpactedPropertyCentroids.features.filter((centroid) => {
          return booleanContains(outlineFeature, centroid);
        });
      })
      .flat();
    setImpactedPropertyCentroids(featureCollection(intersectingFeatures));
  }, [
    clampedHours,
    isManualPrediction,
    totalImpactedPropertyCentroids,
    predictionFeatureCollection,
  ]);
  return impactedPropertyCentroids;
}

import { showToast } from "@app/design-system";
// NOTE: @sentry/tracing MUST be imported when using Sentry.startTransaction or else the transaction is not returned
// @see https://github.com/getsentry/sentry-javascript/issues/4731
import "@sentry/tracing";
import { useUpdatingRef } from "@kablamo/kerosene-ui";
import * as Sentry from "@sentry/nextjs";
import { useQuery } from "@tanstack/react-query";
import type { FeatureCollection } from "geojson";
import { useEffect } from "react";

interface UseGeojsonFromFileUrlParams {
  fileUrl: string | undefined;
  layerName: string;
}

const useGeojsonFromFileUrl = ({
  fileUrl,
  layerName,
}: UseGeojsonFromFileUrlParams) => {
  const result = useQuery({
    queryKey: [fileUrl],
    queryFn: async () => {
      const transaction = Sentry.startTransaction({
        name: layerName,
      });
      const span = transaction.startChild({
        op: "layer-performance",
        description: `${layerName} load time`,
        data: {
          geojsonUrl: fileUrl,
        },
      });

      const fc = await fetch(fileUrl!).then<FeatureCollection>((raw) =>
        raw.json(),
      );

      span.finish();
      transaction.finish();

      return fc;
    },
    enabled: !!fileUrl,
    staleTime: Infinity,
  });

  const layerNameRef = useUpdatingRef(layerName);
  useEffect(() => {
    if (result.error) {
      showToast({
        variant: "error",
        id: `${layerNameRef.current}-error`,
        title: `Failed to retrieve ${layerNameRef.current}`,
      });
    }
  }, [layerNameRef, result.error]);

  return result;
};

export default useGeojsonFromFileUrl;

import { clamp } from "@kablamo/kerosene";
import type { FeatureCollection } from "geojson";
import { useEffect } from "react";
import { MapLevel } from "../../../../config/layers/layers";
import useMapContext from "../../Map/useMapContext";
import { isGeoJsonSource } from "../../types";

const COLOR_SCALE = [
  "rgba(0,0,0,0)",
  0.1,
  "#019C7B",
  2.0,
  "#FFF354",
  7.0,
  "#FAB033",
  14.0,
  "#ED3422",
  30.0,
  "#000000",
] as const;

const DESATURATED_COLOR_SCALE = [
  "rgba(0,0,0,0)",
  0.1,
  "#cfcfcf",
  2.0,
  "#868686",
  7.0,
  "#747474",
  14.0,
  "#878787",
  30.0,
  "#5e5e5e",
] as const;

interface FlameHeightLayerProps {
  geojson: FeatureCollection;
  hours: number;
  opacity: number;
  predictionId: string;
}

const FlameHeightLayer = ({
  geojson,
  hours,
  opacity,
  predictionId,
}: FlameHeightLayerProps) => {
  const map = useMapContext();

  const clampedHours = clamp(0, hours, 11);
  const isExpired = clampedHours < hours;
  const colorScale = isExpired ? DESATURATED_COLOR_SCALE : COLOR_SCALE;

  useEffect(() => {
    map.addSource(predictionId, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
    });
    map.addLayer(
      {
        id: predictionId,
        type: "fill",
        source: predictionId,
      },
      MapLevel.FS_BACKGROUND,
    );

    return () => {
      if (map.getLayer(predictionId)) {
        map.removeLayer(predictionId);
      }
      if (map.getSource(predictionId)) {
        map.removeSource(predictionId);
      }
    };
  }, [map, predictionId]);

  useEffect(() => {
    const source = map.getSource(predictionId);
    if (isGeoJsonSource(source)) {
      source.setData(geojson);
    }
  }, [geojson, map, predictionId]);

  useEffect(() => {
    map.setPaintProperty(predictionId, "fill-color", [
      "step",
      ["at", clampedHours, ["get", "flameHeights"]],
      ...colorScale,
    ]);
  }, [predictionId, clampedHours, map, colorScale]);

  useEffect(() => {
    map.setPaintProperty(predictionId, "fill-opacity", opacity);
  }, [opacity, map, predictionId]);

  return null;
};

export default FlameHeightLayer;

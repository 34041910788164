import mapboxgl, { type Anchor, type LngLatLike } from "mapbox-gl";
import maplibregl from "maplibre-gl";
import React, { useEffect, useRef, useState } from "react";
import useUpdatingRef from "../../../hooks/useUpdatingRef";
import useUnsafeMapContext from "../../map/Map/useUnsafeMapContext";
import { MapPopupPortal } from "./MapPopupPortal";

function extractLngLat(lngLatLike: mapboxgl.LngLatLike): LngLatObject {
  if (Array.isArray(lngLatLike)) {
    // LngLatLike as an array: [number, number]
    const [lng, lat] = lngLatLike;
    return { lng, lat };
  }
  if ("lng" in lngLatLike && "lat" in lngLatLike) {
    const { lng, lat } = lngLatLike;
    // LngLatLike as an object with 'lng' and 'lat': { lng: number; lat: number } or LngLat
    return { lng, lat };
  }
  if ("lon" in lngLatLike && "lat" in lngLatLike) {
    // LngLatLike as an object with 'lon' and 'lat': { lon: number; lat: number }
    const { lon: lng, lat } = lngLatLike;
    return { lng, lat };
  }
  throw new Error("Invalid LngLatLike object");
}

export type MapPopupVariant = "default" | "inverse";

type MapPopupSize = "sm" | "md" | "lg" | "xl";

type MapPopupSizeMap = Record<MapPopupSize, string>;

const mapPopupSizes: MapPopupSizeMap = {
  sm: "14rem",
  md: "16rem",
  lg: "19rem",
  xl: "23rem",
};

export type MapPopupType = "click" | "hover";

export interface MapPopupProps {
  anchor?: Anchor;
  children?: React.ReactNode;
  closeOnClick?: boolean;
  fullWidth?: boolean;
  id: string | number;
  lngLat: LngLatLike;
  offset?: mapboxgl.Offset | maplibregl.Offset;
  onClose: () => void;
  noTip?: boolean;
  size?: MapPopupSize;
  variant?: MapPopupVariant;
  type: MapPopupType;
}

const MapPopup = ({
  anchor,
  children,
  closeOnClick = true,
  fullWidth = true,
  id,
  lngLat,
  offset,
  onClose,
  noTip,
  size = "md",
  variant = "default",
  type,
}: MapPopupProps) => {
  const { lib, map } = useUnsafeMapContext();
  if (!map) {
    throw new Error("MapPopup must be used within a Map child component");
  }

  const onCloseRef = useUpdatingRef(onClose);

  const popupRef = useRef<mapboxgl.Popup | maplibregl.Popup | null>(null);
  const [popupId, setPopupId] = useState<string | null>(null);

  useEffect(() => {
    const close = () => onCloseRef.current();
    let popup: mapboxgl.Popup | maplibregl.Popup;
    if (lib === "mapbox") {
      popup = new mapboxgl.Popup({
        className: ["mapboxgl-popup-contextual"].join(" "),
        focusAfterOpen: false,
        closeButton: false,
        offset: offset as mapboxgl.Offset,
        closeOnClick,
        anchor,
        maxWidth: mapPopupSizes[size],
      })
        .addTo(map)
        .on("close", close);
    } else {
      popup = new maplibregl.Popup({
        className: ["maplibregl-popup-contextual"].join(" "),
        focusAfterOpen: false,
        closeButton: false,
        offset: offset as maplibregl.Offset,
        closeOnClick,
        anchor,
        maxWidth: mapPopupSizes[size],
      })
        .addTo(map)
        .on("close", close);
    }

    popupRef.current = popup;

    return () => {
      popup.off("close", close);
      popup.remove();
      popupRef.current = null;
    };
  }, [anchor, closeOnClick, lib, map, offset, onCloseRef, size]);

  useEffect(() => {
    if (variant !== "inverse") return;

    popupRef.current?.addClassName("inverse");

    return () => {
      popupRef.current?.removeClassName("inverse");
    };
  }, [variant]);

  useEffect(() => {
    const maxWidth = mapPopupSizes[size];

    popupRef.current?.setMaxWidth(maxWidth);
  }, [size]);

  useEffect(() => {
    if (!noTip) return;

    popupRef.current?.addClassName("no-tip");

    return () => {
      popupRef.current?.removeClassName("no-tip");
    };
  }, [noTip]);

  // Avoid passing the whole object as the dependencies of the following effect
  // because they might be a new object with exactly the same numerical lng lat
  const { lng, lat } = extractLngLat(lngLat);

  useEffect(() => {
    if (!popupRef.current) return;

    popupRef.current.setHTML(`<div id="popup-${id}"></div>`);

    setPopupId(`popup-${id}`);

    return () => {
      popupRef.current?.setHTML(``);
    };
  }, [id]);

  useEffect(() => {
    if (!popupRef.current) return;

    popupRef.current.setLngLat({ lat, lng });
  }, [lng, lat]);

  useEffect(() => {
    if (!fullWidth) return;

    popupRef.current?.addClassName("full-width");

    return () => {
      popupRef.current?.removeClassName("full-width");
    };
  }, [fullWidth]);

  useEffect(() => {
    if (type !== "hover") return;
    popupRef.current?.addClassName("hover");

    return () => {
      popupRef.current?.removeClassName("hover");
    };
  }, [type]);

  const onLayout = (loaded: boolean) => {
    // Run set offset with the same offset so we can trigger a relayout of the
    // popup. Mapbox can then calculate whether to adjust the dynamic anchor
    // position
    if (lib === "mapbox") {
      (popupRef.current as mapboxgl.Popup | null)?.setOffset(
        offset as mapboxgl.Offset,
      );
    } else {
      (popupRef.current as maplibregl.Popup | null)?.setOffset(
        offset as maplibregl.Offset,
      );
    }
    // Add a class to reveal the popup once it has been properly positioned.
    // This avoids a flicker for markers positioned towards the top of the
    // screen, where the popup initially appears above the marker flips to
    // below it once the content portals in
    if (loaded) {
      popupRef.current?.addClassName("loaded");
    } else {
      popupRef.current?.removeClassName("loaded");
    }
  };

  return (
    <>
      {popupId && (
        <MapPopupPortal key={id} id={popupId} onLayout={onLayout}>
          {children}
        </MapPopupPortal>
      )}
    </>
  );
};

export default MapPopup;

import { useRef } from "react";

const CACHE_SIZE = 50;

/**
 * First in first out cache with a maximum size.
 * When the cache is full, the oldest item is removed.
 *
 * @param cacheSize {number}
 */
export const useFIFOCache = <T>(cacheSize = CACHE_SIZE) => {
  const cache = useRef<Map<string, T>>(new Map());
  const set = (key: string, value: T) => {
    if (cache.current.size >= cacheSize) {
      const firstKey = Array.from(cache.current.keys())[0];
      cache.current.delete(firstKey);
    }
    cache.current.set(key, value);
  };
  const staticRef = useRef({
    clear: () => cache.current.clear(),
    delete: (key: string) => cache.current.delete(key),
    forEach: (callback: (value: T, key: string, map: Map<string, T>) => void) =>
      cache.current.forEach(callback),
    get: (key: string) => cache.current.get(key),
    has: (key: string) => cache.current.has(key),
    set,
  });

  return staticRef.current;
};

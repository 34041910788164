import type { AxiosResponse } from "axios";
import type { Feature, FeatureCollection, Point } from "geojson";
import type mapboxgl from "mapbox-gl";
import type {
  GetIncidentsId200,
  Incident,
} from "../../../../.rest-hooks/types";
import {
  getAlertLevelIconName,
  getAlertLevelFromIncident,
} from "../../../config/alertLevel";
import { loadIcon } from "../utils/loadImage";

export interface IncidentPointProperties {
  alertLevelPriority?: number;
  iconId: string;
  iconText?: string;
  incidentId: string;
  name: string;
  type: "incident";
}

export type IncidentPointFeature = Feature<Point, IncidentPointProperties>;

export type IncidentPointFeatureCollection = FeatureCollection<
  Point,
  IncidentPointProperties
>;

export const isIncidentPointFeature = (
  feature: Feature,
): feature is IncidentPointFeature => {
  const properties =
    feature.properties as Partial<IncidentPointProperties> | null;
  return (
    feature.geometry.type === "Point" &&
    !!properties?.iconId &&
    !!properties?.incidentId
  );
};

export async function loadAndDisplayIconOnMap(
  geojson: IncidentPointFeature,
  map: mapboxgl.Map | maplibregl.Map,
  mapLayerName: string,
  signal: AbortSignal,
) {
  await loadIcon({
    imageId: geojson.properties.iconId,
    map,
    pixelRatio: 4,
  });

  // If we've aborted whilst the icon was loading, we no longer want to add this layer to the map
  if (signal.aborted) return;

  map.addLayer({
    id: mapLayerName,
    type: "symbol",
    source: {
      type: "geojson",
      data: geojson,
    } satisfies maplibregl.GeoJSONSourceSpecification,
    layout: {
      "icon-size": 1,
      "icon-image": ["get", "iconId"],
      "icon-allow-overlap": true,
      "icon-optional": false,
    },
  });
}

export const incidentPointFromIncident = (
  incident: Incident,
): IncidentPointFeature => ({
  type: "Feature",
  geometry: incident.attributes.location,
  properties: {
    iconId: getAlertLevelIconName(getAlertLevelFromIncident(incident)),
    incidentId: incident.id,
    name: incident.attributes.name,
    type: "incident",
  },
});

export const selectIncidentPoint = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: AxiosResponse<GetIncidentsId200, any>,
): IncidentPointFeature => {
  const incident = data.data.data;

  return incidentPointFromIncident(incident);
};

import LayersDrawerCell from "../LayersDrawerCell";
import LegendDrawerCell from "../LegendDrawerCell";
import MapDrawerCell from "../MapDrawerCell";
import type { DrawerHandleLayout } from "./DrawerHandle";
import DrawerOverlayGrid from "./DrawerOverlayGrid";

interface DrawerOverlayProps {
  children?: React.ReactNode;
  handleLayout?: DrawerHandleLayout;
}

const DrawerOverlay = ({ children, handleLayout }: DrawerOverlayProps) => {
  return (
    <DrawerOverlayGrid>
      <MapDrawerCell handleLayout={handleLayout} />
      <LegendDrawerCell handleLayout={handleLayout} />
      <LayersDrawerCell handleLayout={handleLayout} />
      {children}
    </DrawerOverlayGrid>
  );
};

export default DrawerOverlay;

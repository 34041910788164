import { Skeleton } from "@app/design-system";
import styled, { css } from "styled-components";
import { formatNumber } from "../../../utils/formatNumber/formatNumber";

const StyledLabelWithCount = styled.div`
  display: grid;
  width: fit-content;
  grid-template-columns: auto 1fr;
  grid-template-areas: "label meta";
  gap: 4px;
`;

const StyledLabel = styled.span`
  grid-area: label;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCount = styled.span`
  grid-area: meta;
  flex-shrink: 0;
  font-weight: normal;
`;

interface StyledSkeletonProps {
  isVisible: boolean;
}

const StyledSkeleton = styled.div<StyledSkeletonProps>`
  grid-area: meta;
  min-width: 1ch;
  ${(p) =>
    !p.isVisible &&
    css`
      visibility: hidden;
    `}
`;

interface LabelWithCountProps {
  children?: React.ReactNode;
  count: number | undefined;
  hideZeroCount?: boolean;
  isLoading?: boolean;
}

const LabelWithCount = ({
  children,
  count,
  hideZeroCount,
  isLoading,
}: LabelWithCountProps) => {
  if (!isLoading && !count) {
    return <>{children}</>;
  }

  if (hideZeroCount && !count) {
    return <>{children}</>;
  }

  return (
    <StyledLabelWithCount>
      <StyledLabel>{children}</StyledLabel>
      <StyledCount>({formatNumber(count ?? 0)})</StyledCount>
      <StyledSkeleton isVisible={!!isLoading}>
        <Skeleton flex />
      </StyledSkeleton>
    </StyledLabelWithCount>
  );
};

export default LabelWithCount;

import { FieldGrid, Text } from "@app/design-system";
import type { PotentialImpactsInteractionProperties } from "../../map/PotentialImpacts/interactions";

export interface PotentialImpactsHoverPopupDetailProps {
  hours: number;
  properties: PotentialImpactsInteractionProperties;
  showBurnProbability: boolean;
}

const PotentialImpactsHoverPopupDetail = ({
  hours,
  properties,
  showBurnProbability,
}: PotentialImpactsHoverPopupDetailProps) => {
  const burnProbability = `${Math.round(
    properties.burnProbabilities[hours] * 100,
  )}%`;

  return (
    <>
      {properties.type && (
        <Text size="eyebrowDefault" variant="weak">
          {properties.type}
        </Text>
      )}
      <Text size="subtitleMd">{properties.name || <>Name: &mdash;</>}</Text>
      {showBurnProbability && (
        <FieldGrid>
          <FieldGrid.Item label="Impact prob.:">
            {burnProbability}
          </FieldGrid.Item>
        </FieldGrid>
      )}
    </>
  );
};

export default PotentialImpactsHoverPopupDetail;

import type { Feature } from "geojson";
import type { LngLatLike } from "mapbox-gl";
import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isPointFeature } from "../types";

const getHumanReadableLabelFromLayerId = (symbolName: string) => {
  return symbolName
    ?.replaceAll("_", " ")
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export type PotentialImpactsInteractionProperties =
  FeatureInteractionProperties & {
    burnProbabilities: number[];
    name: string;
    type: string;
  };

export type PotentialImpactsInteractionState =
  FeatureInteractionState<PotentialImpactsInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  PotentialImpactsInteractionProperties
> = (feature: Feature): PotentialImpactsInteractionProperties | null => {
  if (!isPointFeature(feature)) return null;

  const lngLat = feature.geometry.coordinates as LngLatLike;

  const name = feature.properties?.facname;
  const symbolName = feature.properties?.symbolname;
  const burnProbabilitiesString = feature.properties?.burnProbabilities;

  const burnProbabilities = JSON.parse(burnProbabilitiesString);

  const type = getHumanReadableLabelFromLayerId(symbolName);

  if (!feature.id) {
    throw new Error("Unable to identify potential impact");
  }

  return {
    burnProbabilities,
    featureId: feature.id,
    lngLat,
    name,
    type,
  };
};

import type { RapidAppreciationPredictionAttributes } from "../../../../../.rest-hooks/types/predictions.yml";
import useGeojsonFromFileUrl from "../../../../hooks/useGeojsonFromFileUrl";
import useStableFileUrl from "../../../../hooks/useStableFileUrl";
import { useRelativeHours } from "../../../util/TimelineProvider/TimelineProvider";
import { useFitToGeojson } from "../../FitToGeojson/FitToGeojson";
import EmberDensityLayer from "./EmberDensityLayer";

interface EmberDensityByPredictionProps {
  opacity: number;
  predictionAttributes: RapidAppreciationPredictionAttributes;
  predictionId: string;
}

const EmberDensityByPrediction = ({
  opacity,
  predictionAttributes,
  predictionId,
}: EmberDensityByPredictionProps) => {
  const { result, validFrom } = predictionAttributes;

  const fileUrl = useStableFileUrl(result?.emberDensityFileUrl);

  const { data: geojson, isError } = useGeojsonFromFileUrl({
    fileUrl,
    layerName: "ember density layer",
  });

  useFitToGeojson({ geojson, id: predictionId, isError });

  const validFromUnixMilliseconds = validFrom ? validFrom * 1000 : undefined;

  const hours = useRelativeHours(validFromUnixMilliseconds);

  if (!result?.emberDensityFileUrl || !geojson) {
    return null;
  }

  return (
    <EmberDensityLayer
      geojson={geojson}
      hours={hours}
      opacity={opacity}
      predictionId={predictionId}
    />
  );
};

export default EmberDensityByPrediction;

import type mapboxgl from "mapbox-gl";
import type maplibregl from "maplibre-gl";

export const getMaxAllowableOffset = (map: mapboxgl.Map | maplibregl.Map) => {
  const canvas = map.getCanvas();
  // Attempt to fix a bug where getCanvas() is undefined
  if (!canvas) return 0;
  const mapWidth = canvas.width / window.devicePixelRatio;
  const mapExtentWidth = map.getBounds().getEast() - map.getBounds().getWest();
  return mapExtentWidth / mapWidth;
};

import styled from "styled-components";

const StyledDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface DrawerContentProps {
  children?: React.ReactNode;
}

const DrawerContent = ({ children }: DrawerContentProps) => {
  return <StyledDrawerContent>{children}</StyledDrawerContent>;
};

export default DrawerContent;

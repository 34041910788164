import {
  type ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import useUpdatingRef from "../../../hooks/useUpdatingRef";

interface MapPopupPortalProps {
  children: ReactNode;
  id: string;
  onLayout: (loaded: boolean) => void;
}

export const MapPopupPortal = ({
  children,
  id,
  onLayout,
}: MapPopupPortalProps) => {
  const ref = useRef<HTMLElement | null>(null);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  ref.current = target;

  const onLayoutRef = useUpdatingRef(onLayout);

  useEffect(() => {
    if (id) {
      const element = document.querySelector(`#${id}`) as HTMLElement;
      setTarget(element);
    }
  }, [id]);

  useLayoutEffect(() => {
    if (target) {
      const onCurrentLayout = onLayoutRef.current;
      onCurrentLayout(true);

      return () => onCurrentLayout(false);
    }
  }, [onLayoutRef, target]);

  if (!ref.current) return null;

  return createPortal(children, ref.current);
};

import type { ParsedUrlQuery } from "querystring";

const getStringQueryParam = (
  query: ParsedUrlQuery,
  key: string,
): string | undefined => {
  const param = query[key];

  if (typeof param === "string" || typeof param === "undefined") {
    return param;
  }

  return undefined;
};

export default getStringQueryParam;

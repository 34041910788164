import { Accordion, FieldGroup } from "@app/design-system";
import styled from "styled-components";
import type { LayerSet, LayerWithLegend } from "../../../config/layers/layers";
import FireFeaturesLegendList from "./FireFeaturesLegendList";
import PredictionLegendList from "./PredictionLegendList";

const StyledLegendList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
`;

const StyledLegends = styled.div`
  display: grid;
  padding: 0.75rem;
  gap: 1rem;
`;

interface LegendListProps {
  legendLayerSets: [LayerSet, LayerWithLegend[]][];
}

const LegendList = ({ legendLayerSets }: LegendListProps) => {
  return (
    <StyledLegendList>
      <PredictionLegendList />
      <FireFeaturesLegendList />
      {legendLayerSets.map(([layerSet, layers]) => {
        return (
          <Accordion
            key={layerSet.id}
            title={`${layerSet.label} (${layers.length})`}
          >
            <StyledLegends>
              {layers.map(
                ({ legend: { component: LegendComponent, id, label } }) => (
                  <FieldGroup key={id} size="sm" title={label}>
                    <LegendComponent id={id} />
                  </FieldGroup>
                ),
              )}
            </StyledLegends>
          </Accordion>
        );
      })}
    </StyledLegendList>
  );
};

export default LegendList;

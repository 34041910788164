import React, { createContext, useContext } from "react";
import type { UseMapRailReturn } from "./useMapRail";

type MapRailContextValue = UseMapRailReturn;

const MapRailContext = createContext<MapRailContextValue | undefined>(
  undefined,
);

type MapRailProviderProps = UseMapRailReturn & {
  children?: React.ReactNode;
};

export const MapRailProvider = ({
  children,
  ...value
}: MapRailProviderProps) => {
  return (
    <MapRailContext.Provider value={value}>{children}</MapRailContext.Provider>
  );
};

export const useMapRailContext = () => {
  const value = useContext(MapRailContext);

  if (!value) {
    throw new Error("useMapRailContext must be used inside a MapRailProvider");
  }

  return value;
};

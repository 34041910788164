import { Accordion } from "@app/design-system";
import styled from "styled-components";
import { fireFeatures } from "../../../config/layers/layers";
import { FireFeaturesLegend } from "../../legends/Legends";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";

const StyledLegend = styled.div`
  padding: 0.75rem;
`;

const FireFeaturesLegendList = () => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fireFeatures.id)) {
    return null;
  }

  return (
    <Accordion title={`${fireFeatures.legend?.label} (1)`}>
      <StyledLegend>
        <FireFeaturesLegend />
      </StyledLegend>
    </Accordion>
  );
};

export default FireFeaturesLegendList;

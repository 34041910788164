import { Accordion, FieldGroup } from "@app/design-system";
import styled from "styled-components";
import { predictionVisualisationLegendMap } from "../../../config/predictionProduct";
import { usePredictions } from "../../ui/PredictionsProvider/PredictionsProvider";

const StyledLegends = styled.div`
  display: grid;
  padding: 12px;
  gap: 16px;
`;

const PredictionLegendList = () => {
  const { getActiveVisualisationTypes } = usePredictions();

  const activeVisualisationTypes = getActiveVisualisationTypes();

  if (!activeVisualisationTypes.length) {
    return null;
  }

  const activeVisualisationTypesCount = activeVisualisationTypes.length;

  return (
    <Accordion
      title={`Prediction Visualisations (${activeVisualisationTypesCount})`}
    >
      <StyledLegends>
        {activeVisualisationTypes.map((type) => {
          const { component: LegendComponent, label } =
            predictionVisualisationLegendMap[type];

          return (
            <FieldGroup key={type} size="sm" title={label}>
              <LegendComponent />
            </FieldGroup>
          );
        })}
      </StyledLegends>
    </Accordion>
  );
};

export default PredictionLegendList;

import { useState } from "react";

const stripQueryFromUrl = (url: string | undefined) => {
  if (!url) return url;

  return url.split("?")[0];
};

const useStableFileUrl = (fileUrl: string | undefined) => {
  const [stableFileUrl, setStableFileUrl] = useState<string | undefined>(
    fileUrl,
  );

  if (stripQueryFromUrl(fileUrl) !== stripQueryFromUrl(stableFileUrl)) {
    setStableFileUrl(fileUrl);
  }

  return stableFileUrl;
};

export default useStableFileUrl;

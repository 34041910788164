import { Layers, Tab, TabList, TabPanel, Tabs } from "@app/design-system";
import React, { useState } from "react";
import LabelWithCount from "../../ui/LabelWithCount/LabelWithCount";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import DrawerCell from "../DrawerOverlay/DrawerCell";
import type { DrawerHandleLayout } from "../DrawerOverlay/DrawerHandle";
import { DrawerOverlayGridArea } from "../DrawerOverlay/DrawerOverlayGrid";
import LayersDrawer from "./LayersDrawer";
import MarkupDrawer from "./MarkupDrawer";
import ResetLayersButton from "./ResetLayersButton";

const layersDrawerTabs = {
  LAYERS: 0,
  MARKUP: 1,
} as const;

interface LayersDrawerCellProps {
  handleLayout?: DrawerHandleLayout;
}

const LayersDrawerCell = ({ handleLayout }: LayersDrawerCellProps) => {
  const {
    visualiserState: { toolsets },
  } = useVisualiser();

  const { getLayersBySource } = useActiveLayersContext();

  const layers = getLayersBySource("layers-drawer");
  const listLayersCount = layers.size;
  const showResetLayersButton = listLayersCount > 0;

  const [selectedIndex, setSelectedIndex] = useState<number>(
    layersDrawerTabs.LAYERS,
  );

  if (!toolsets.length) {
    return (
      <DrawerCell
        alignment="end"
        footer={showResetLayersButton && <ResetLayersButton />}
        gridArea={DrawerOverlayGridArea.LAYERS}
        handleLayout={handleLayout}
        icon={Layers}
        label={
          <LabelWithCount count={listLayersCount} hideZeroCount>
            Layers
          </LabelWithCount>
        }
        data-testid="layers-markup-drawer"
      >
        <LayersDrawer />
      </DrawerCell>
    );
  }

  return (
    <DrawerCell
      alignment="end"
      footer={
        selectedIndex === layersDrawerTabs.LAYERS &&
        showResetLayersButton && <ResetLayersButton />
      }
      gridArea={DrawerOverlayGridArea.LAYERS}
      handleLayout={handleLayout}
      icon={Layers}
      label="Layers & Markup"
      data-testid="layers-markup-drawer"
    >
      <Tabs selectedIndex={selectedIndex} onSelect={setSelectedIndex}>
        <TabList variant="transparent">
          <Tab flex>
            <LabelWithCount count={listLayersCount} hideZeroCount>
              Layers
            </LabelWithCount>
          </Tab>
          <Tab flex>Markup</Tab>
        </TabList>
        <TabPanel>
          <LayersDrawer />
        </TabPanel>
        <TabPanel>
          <MarkupDrawer />
        </TabPanel>
      </Tabs>
    </DrawerCell>
  );
};

export default LayersDrawerCell;

import {
  Checkbox,
  ImageRadioButton,
  VerticalDivider,
} from "@app/design-system";
import styled from "styled-components";
import {
  baseMapLayers,
  type BaseLayer,
} from "../../../config/layers/baseLayers";
import { type LayerConfig, terrainLayer } from "../../../config/layers/layers";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";

const StyledMapViewControls = styled.fieldset`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0 0 0 0.5rem;
`;

const StyledBaseMaps = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledDivider = styled.div`
  color: ${(p) => p.theme.colors.neutrals.borderWeak};
`;

const MapViewControls = () => {
  const { is3DEnabled } = useVisualiser();

  const { isLayerActive, activateLayer, deactivateLayer, setBaseMap } =
    useActiveLayersContext();

  const onBaseMapChange = (layer: BaseLayer) => () => {
    setBaseMap({
      id: layer.id,
    });
  };

  const onOptionLayerChange =
    (layer: LayerConfig) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        activateLayer({
          id: layer.id,
          source: "option-layer",
        });
      } else {
        deactivateLayer({
          id: layer.id,
        });
      }
    };

  return (
    <StyledMapViewControls>
      <StyledBaseMaps>
        {baseMapLayers.map((layer) => (
          <ImageRadioButton
            key={layer.id}
            onChange={onBaseMapChange(layer)}
            checked={isLayerActive(layer.id)}
            src={layer.imageSrc}
            value={layer.id}
            data-testid={layer.id}
          />
        ))}
      </StyledBaseMaps>
      {is3DEnabled && (
        <Checkbox
          checked={isLayerActive(terrainLayer.id)}
          key={terrainLayer.id}
          label={terrainLayer.label}
          onChange={onOptionLayerChange(terrainLayer)}
          data-testid={`${terrainLayer.id}-checkbox`}
        />
      )}
      <StyledDivider>
        <VerticalDivider height={24} />
      </StyledDivider>
    </StyledMapViewControls>
  );
};

export default MapViewControls;

import type { PredictionImpacts } from "../../../.rest-hooks/types/predictions.yml";

interface GetTotalImpactCountParams {
  impacts: PredictionImpacts | undefined;
}

const getTotalImpactCount = ({
  impacts,
}: GetTotalImpactCountParams): number | undefined => {
  if (!impacts) return undefined;

  return (
    impacts.criticalInfrastructure.totalCount +
    impacts.habitableProperties.totalCount
  );
};

export default getTotalImpactCount;

export const getTotalHabitablePropertiesCount = ({
  impacts,
}: GetTotalImpactCountParams): number | undefined => {
  if (!impacts) return undefined;

  return impacts.habitableProperties.totalCount;
};

export const getTotalCriticalInfrastructureCount = ({
  impacts,
}: GetTotalImpactCountParams): number | undefined => {
  if (!impacts) return undefined;

  return impacts.criticalInfrastructure.totalCount;
};
